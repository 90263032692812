import { useState, useEffect, useRef } from 'react';

import EditorJS from '@editorjs/editorjs';
// import Header from '@editorjs/header';
// import NestedList from '@editorjs/nested-list';
// import Image from '@editorjs/image';

import { RegisterButton, RegisterButtonActive } from '@components/buttons';
import { EditTextInput } from '@components/forms';

import NotebookService from '@services/notebookService'

import * as Style from './textEditor.style'
import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.textEditor

export const TextEditor = ({
  notebook,
  setNotebook,
}: any) => {
  const ejInstance = useRef(null);
  const [alreadyInit, setAlreadyInit] = useState(false);
  const [register, setRegister] = useState(false);

  useEffect(() => {
    if (notebook.data && !alreadyInit) {
      if (ejInstance.current) {
        ejInstance.current.destroy();
        ejInstance.current = null;
      }
      initEditor();
    }
  }, [notebook.data]);

  useEffect(() => {
    return () => {
      if (ejInstance.current) {
        ejInstance.current.destroy();
        ejInstance.current = null;
      }
    }
  }, []);

  const initEditor = () => {
    setAlreadyInit(true)
    const editor = new EditorJS({
      //  Id of Element that should contain the Editor
      holder: "editorjs",
      data: notebook.data,
      // placeholder: STATIC_VAR.PLACEHOLDER,
      minHeight: 30,
      /** Available Tools list.
       * Pass Tool's class or Settings object for each Tool you want to use
       */
      tools: {},
      // defaultBlock: 'myOwnParagraph',
      onReady: () => {
        ejInstance.current = editor;
        setAlreadyInit(false)
      },
       onChange: (api, event) => {
          if (register === false) {
            setRegister(true)
          }
        },
    });
  }
  const onClickHandler = () => {
    ejInstance.current
      .save().then((outputData) => {
        NotebookService.updateNotebookService(notebook.id, { 'id': notebook.id, 'data': outputData })
        setNotebook({ ...notebook, data: outputData })
      })
      .catch((error) => { console.log("Saving failed: ", error) });
  };
  // const updateDescription = (newValue) => {
  //   NotebookService.updateNotebookService(notebook.id, { 'id': notebook.id, 'description': newValue })
  //   setNotebook({ ...notebook, 'description': newValue })
  // }
  const editNotebookTitle = (newTitle: string) => {
    NotebookService.updateNotebookService(notebook.id, { 'id': notebook.id, 'title': newTitle })
      .catch((error: any) => console.log('error', error))
    setNotebook({ ...notebook, 'title': newTitle });
  };
  return (
    <div>
      <Style.Head>
        <Style.HeadNotebook>
        Écrivez vos notes 👇
          {/* <EditTextInput
            value={notebook?.title}
            editInputHandler={editNotebookTitle} /> */}
        </Style.HeadNotebook>

        <Style.ButtonDiv>
          { register ?
              <RegisterButtonActive onClick={() => {
                onClickHandler()
                setRegister(false)
              }} />
            :
              <RegisterButton  />
          }
        </Style.ButtonDiv>
      </Style.Head>

      <Style.Notes id="editorjs" />
    </div>
  );
}