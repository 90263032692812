import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import UserProfileService from "@services/userProfileService";

const LogoutCss = styled.p`
  font-size: 18px;
  margin: 5px;
  color: #7b7b7b;
  &:hover {
    color: rgba(202, 109, 92, 1);
  }
`;

export const Logout = () => {

  let navigate = useNavigate();
  useEffect(() => {
  })
  
  const doLogout = (event) => {
    event.preventDefault();
    UserProfileService.token_blacklist({
      "refresh_token": localStorage.getItem("refresh_token")
    }).then(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_name');
      navigate(('/connect'))
    }).catch((error) => console.log('error logout', error))
  }
  return (
    <LogoutCss onClick={doLogout}>Se déconnecter</LogoutCss>
  )
}

