import { useNavigate } from 'react-router-dom'
import { useState } from 'react';

import UserProfileService from "@services/userProfileService";
import { TextFieldBasic, HiddenTextField, InputButton } from '@components/forms'

// import JSON_FILE from '@context/text/francais/word.json';
// const STATIC_VAR = JSON_FILE.pages.connect
import * as Style from './authentication.style'

type INITIAL_STATE = {
  password: string;
  passwordConfirm: string;
}

export const NewPassword = () => {
  const [passwords, setpasswords] = useState<INITIAL_STATE>({ password: '', passwordConfirm: '' });
  const [emailSend, setEmailSend] = useState<boolean>(false);
  const tokenReset = location.search.split('=')[1];
  let navigate = useNavigate();

  const submitForm = (event) => {
    event.preventDefault();
    const data = {
      password: passwords.password,
      token: tokenReset
    }
    UserProfileService.password_reset(data, tokenReset).then((data) => {
      setEmailSend(true)
    })
  }

  const handleChange = (event) => {
    setpasswords({
      ...passwords,
      [event.target.id]: event.target.value,
    });
  };

  return (
    <Style.Main>
      {emailSend == false ?
        <div>
          <Style.SimpleTitle >Choisis un nouveau mot de passe 🗝 </Style.SimpleTitle>
          <form >
            <TextFieldBasic id='password' label='Nouveau mot de passe' value={passwords.password} onChange={handleChange} />
            {/* <TextFieldBasic id='passwordConfirm' label='Confirmer le mot de passe' value={passwords.passwordConfirm} onChange={handleChange} /> */}
            <InputButton label='envoyer' onClick={submitForm} />
          </form>
        </div>
        : <>
          <Style.Box>
            <Style.SimpleTitle >Mot de passe bien modifié 🗝 </Style.SimpleTitle>
            <Style.SimpleLink onClick={() => navigate(('/connect'))}> Retour à la page d'accueil </Style.SimpleLink>
          </Style.Box>
        </>
      }
    </Style.Main>
  )
}