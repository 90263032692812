import styled from "styled-components";

export const Title = styled.div`
  padding: 5px;
  text-align: center;
  font-size:25px;
  font-weight: 390;
`;
export const Box = styled.div`
  text-align: justify;
  font-size:20px;
  font-weight: 390;
  padding-right: 30px;
  padding-left: 30px;
`;

