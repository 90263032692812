import * as Style from './emptyLibrary.style';
import { RiDeleteBin5Line } from "react-icons/ri";

export const EmptyLibrary = () => {

  return (
    <>
      <Style.Box>
        <Style.Title>
          Pour l'instant, votre bibliothèque est vide.<br /> Pour commencer à la créer, il vous suffit de suivre les étapes suivantes.<br /><br />
        </Style.Title>
        - En premier, cliquez sur la bande grise supérieure pour décrire en quelques mots votre bibliothèque. <br /><br />

        - Ensuite, cliquez sur votre nom d'utilisateurice en haut à droite pour voir le menu et accéder aux instructions. <br /><br />
        - Enfin, choisissez, au fur et à mesure, les livres que vous souhaitez ajouter à votre bibliothèque en les classant par catégorie.<br /><br />
        - Vous pouvez créer et nommer de nombreuses catégories selon votre choix : Roman, Essai, Philosophie, Géopolitique…
        Ou Livres recommandés, Livres sans intérêt, etc..<br /><br />
        - Pour chaque livre, vous disposez de plusieurs fonctionnalités :
        ⭐ | Notes | <RiDeleteBin5Line size={15} /><br /><br />
        - Votre bibliothèque est consultable par tous les autres utilisateurices de Lesen.<br /><br />
        - Des notifications (à droite de votre écran) vous informent dès qu'un nouveau livre est ajouté dans une autre bibliothèque.<br /><br />
        - N'oubliez pas de compléter votre Profil pour que vos ami(e)s de Lesen découvrent vos goûts littéraires.
      </Style.Box>
    </>
  )
}

