import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { MenuHeadBar } from '@components/menu';
import { TextEditor } from '@components/notebook';
import { GoBackButton } from '@components/buttons';
import { SameReadingUserList } from '@components/user';
import { ReadingDescription } from '@components/reading/singleReading';

import { getNotebookData } from '@utils/notebookUtils';

import { ReadingBookGoogleAPI, Notebook } from '@types_app/index';

import * as Style from '../notebookGeneric.style';

export const MyNotebook = () => {

  const [userId, setUserId] = useState<number>()
  const [readingId, setReadingId] = useState<number>()
  const [reading, setReading] = useState<ReadingBookGoogleAPI>()
  const [notebook, setNotebook] = useState<Notebook>()

  const { id } = useParams();

  useEffect(() => {
    if (id !== undefined)
      getNotebookData(id, setReadingId, setReading, setUserId, setNotebook)
  }, [id])


  useEffect(() => {
    console.log('reading', reading)
  }, [reading])

  return (
    <>
      <GoBackButton />
      <MenuHeadBar />

      <Style.Main>
        <Style.MainNotebook>
          <Style.Description>
            <ReadingDescription reading={reading} />
          </Style.Description>

          <Style.BodyNotebook>
            { notebook ? <TextEditor notebook={notebook} setNotebook={setNotebook} /> : <></> }
          </Style.BodyNotebook>

        </Style.MainNotebook>

        <SameReadingUserList readingId={readingId} userId={userId} />
      </Style.Main>
    </>
  )
}
