import { useState, useEffect } from 'react';

import { Navigate } from 'react-router';

export const AuthOnly = ({ children }) => {
  
  const [isAuthenticated] = useState(localStorage.getItem('user_id'));
  // useEffect(() => {
  //   console.log('isAuthenticated', isAuthenticated)
  // }, [])

  return isAuthenticated ? children : <Navigate to='/connect' />;
};
