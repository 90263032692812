import styled from "styled-components";

export const GridMain = styled.div`
  background-color: rgba(226, 150, 95, 0.2);
  padding: 2%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 5px;
`;
export const Section = styled.div`
  border: solid 2px rgb(202, 109, 92);
  margin: 12px;
  padding: 18px;
`;
export const TitleText = styled.div`
  font-size:38px;
  font-weight: 590;
  color: rgb(54,55,54);
  text-align: center;
  margin-bottom: 10px;
`;
export const Question = styled.div`
  font-size:25px;
  font-weight: 590;
  margin-bottom: 5px;
`;
export const Response = styled.div`
  margin-left:15px;
  font-size:19px;
`;
export const Section2 = styled(Section)`
  text-align: center;
`;
