import styled from 'styled-components';

export const ProfileButtonCss = styled.button`
    background-color: rgba(117, 197, 225, 1);
    border-radius: 100px;
    box-shadow: rgba(117, 197, 225, .2) 0 -25px 18px -14px inset,rgba(117, 197, 225, .15) 0 1px 2px,rgba(117, 197, 225, .15) 0 2px 4px,rgba(117, 197, 225, .15) 0 4px 8px,rgba(117, 197, 225, .15) 0 8px 16px,rgba(117, 197, 225, .15) 0 16px 32px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
    padding: 7px 20px;
    margin: 4px; 
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    &:hover {
        .button {
            box-shadow: rgba(117, 197, 225,.35) 0 -25px 18px -14px inset,rgba(117, 197, 225,.25) 0 1px 2px,rgba(117, 197, 225,.25) 0 2px 4px,rgba(117, 197, 225,.25) 0 4px 8px,rgba(117, 197, 225,.25) 0 8px 16px,rgba(117, 197, 225,.25) 0 16px 32px;
            transform: scale(1.05) rotate(-1deg);
        }
      }
}
`
