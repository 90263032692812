import FollowedService from '@services/followedService'

export const getFollowed = (setFollowed) => {
  FollowedService.get(localStorage.getItem('user_id')).then((res: any) => {
    setFollowed(res.data)
  })
};

export const setUserToFollow = (id_to_follow, action) => {
  const data = {
    'to_follow': action,
    'data': { 'user_followed': id_to_follow },
  }
  FollowedService.update(data, localStorage.getItem('user_id'))
};
