import { useState, useEffect } from 'react';
import parse from 'html-react-parser';

import { ReadingBookGoogleAPI, BookGoogleAPI } from '@types_app/index';

import { splitDate } from '@utils';

import * as Style from './completeReadingDescription.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.reading.singleReading

type Props = {
  reading: ReadingBookGoogleAPI;
}
const urlImg = 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Birds_Worth_Knowing_0266.png/640px-Birds_Worth_Knowing_0266.png'

export const CompleteReadingDescription = ({
  reading
}: Props) => {

  const [read, setRead] = useState<BookGoogleAPI>({} as BookGoogleAPI)

  useEffect(() => {
    if (reading) setRead(reading.data)
    console.log('read', read)
  }, [reading])

  const getImgUrl = (readInfo) => {
    if ('volumeInfo' in readInfo) {
      if ('imageLinks' in readInfo.volumeInfo) {
        if ('thumbnail' in readInfo.volumeInfo.imageLinks) {
          return readInfo.volumeInfo.imageLinks.thumbnail
        } else if ('smallThumbnail' in readInfo.volumeInfo.imageLinks) {
          return readInfo.volumeInfo.imageLinks.smallThumbnail
        }
      } else
        return urlImg
    }
  }

  return (
    <div>
      {read && read.volumeInfo ?
        <Style.FlexGridCss>
          <Style.MainInfo>
            <Style.ImgCss src={getImgUrl(read)} />
            <div style={{ display: 'block' }}>
              <Style.TitleCss>
                {read.volumeInfo.title}
                <Style.SudtitleCss> {read.volumeInfo.subtitle ? ', ' + read.volumeInfo?.subtitle : ''} </Style.SudtitleCss>
              </Style.TitleCss>

              <Style.FlexGridCss>
                <Style.AuthorCss>
                  {read.volumeInfo.authors ? <Style.TitleCss>{read.volumeInfo?.authors.join(' - ')}</Style.TitleCss> : STATIC_VAR.UNKNOW} &#160;

                  {read.volumeInfo.publishedDate ?
                    <Style.DateCss> ({splitDate(read.volumeInfo.publishedDate)}) </Style.DateCss>
                    : <></>}

                  {read.volumeInfo?.publisher ?
                    <div> {STATIC_VAR.PUBLISHER}&#160; <Style.TitleCss>{read.volumeInfo?.publisher}</Style.TitleCss> </div>
                    : <></>}

                  {read.volumeInfo?.pageCount ?
                    <div> {STATIC_VAR.NUMBER_PAGE}&#160;{read.volumeInfo?.pageCount} </div>
                    : <></>}

                  {read.accessInfo?.publicDomain ?
                    <div> {STATIC_VAR.PUBLIC_DOMAIN}&#160; <>{read.accessInfo?.publicDomain}</> </div>
                    : <></>}
                </Style.AuthorCss>
              </Style.FlexGridCss>
            </div>
          </Style.MainInfo>

          <Style.Description  >
            <Style.Category> {read.volumeInfo?.categories} </Style.Category>
            {parse(read.volumeInfo.description)}
          </Style.Description>



        </Style.FlexGridCss>
        : <></>
      }
    </div>
  )
};
