import axiosInstance from '@services/api'

const baseUrl = '/user-profile/';

class UserProfileService {

    create = data => {
        const url = baseUrl + `user/create/`
        return axiosInstance.post(url, data).then(response => {
            return response
        }).catch(error => {
            return error
        })
    }

    confirmEmail = params => {
        const url = baseUrl + `email-validation/` + params
        return axiosInstance.get(url).then(response => {
            return response
        }).catch(error => {
            return error
        })
    }

    token_obtain = data => {
        const url = baseUrl + `token/obtain/`
        return axiosInstance.post(url, data).then(response => {
            axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access
            return response
        }).catch(error => {
            throw error
        })
    }
    token_blacklist = async data => {
        const url = baseUrl + `token/blacklist/`
        const repp = await axiosInstance.post(url, data)
        // return axiosInstance.post(url, data).then(response => {
        //     axiosInstance.defaults.headers['Authorization'] = null;
        //     return response
        // }).catch(err => {
        //     console.log('Error: ', err.request._header)
        // })
    }
    password_reset = (data, token) => {
        const url = `/password_reset/confirm/?token=` + token
        return axiosInstance.post(url, data).then(response => {
        }).catch(error => {
            throw error
        })
    }

    ask_password_reset = data => {
        const url = baseUrl + `v1/api/auth/passwordreset/ `

        return axiosInstance.post(url, data).then(response => {
        }).catch(error => {
            throw error
        })
    }

    passwordConfirm = (data, token) => {
        const url = baseUrl + `v1/api/auth/passwordreset/confirm/?token=` + token

        return axiosInstance.post(url, data).then(response => {
        }).catch(error => {
            throw error
        })
    }

    getSettingsService  = (userId) => {
        const url = baseUrl + `v1/settings/${userId}/`
        return axiosInstance.get(url).then((res) => {
            return res
        }).catch(error => { throw error });
    };  

    updateSettingsService  = (userId, data) => {
        const url = baseUrl + `v1/settings/${userId}/`
        return axiosInstance.patch(url, data).then((res) => {
            return res
        }).catch(error => { throw error });
    };  
}

export default new UserProfileService();
