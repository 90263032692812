import styled from 'styled-components';

export const BntEx = styled.div`
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  padding: 3px 15px 5px 15px;
  cursor: pointer;
  :hover {
    font-size:23px;
  }
`;