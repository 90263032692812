import styled from "styled-components";
import painting from '@assets/ladybook.png'

// const url = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/KDE_mascot_Konqi_for_bug_reports.png/482px-KDE_mascot_Konqi_for_bug_reports.png?20170717054552'
// background-image: linear-gradient(rgba(255, 255, 255, 0.7),
// rgba(255, 255, 255, 0.7)), 
// url(${url});
export const GridMain = styled.div`
  background-color: rgba(226, 150, 95, 0.2);
  padding: 2%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 5px;
`;
export const TitleText = styled.div`
font-size: min(10vw, 38px);
  font-weight: 590;
  color: rgb(54,55,54);
  text-align: center;
  margin-bottom: 10px;
`;
export const TextBody = styled.div`
  border: solid 2px rgb(202, 109, 92);
  margin: 12px;
  padding: 18px;
  text-align: center;
  font-size: min(10vw, 25px);

  font-weight: 200;
`;
export const Mail = styled.div`
  font-size: min(5vw, 25px);
  font-weight: 350;
  text-decoration: underline;
  color: rgb(117, 197, 225);
  margin-top: 15px;
`;

export const LadyBook = styled.img`
  height: min(30vw, 80vw);
`;