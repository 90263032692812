import { useState, useEffect } from 'react';

import { StandardLink } from '@components/links';
import { LittleDescription } from '@components/reading/singleReading';
import { DeleteBinButton, ToggleStarButton } from '@components/buttons';

import { ReadingLibrary_m2m } from '@types_app/index';

import * as Style from './dragAndDropElement.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.reading.singleReading

export const DragAndDropElement = ({
  reading,
  updateStarStatus,
  setReadingDelete,
}: Props) => {
  const [isNotes, setIsNotes] = useState(false)

  useEffect(() => {
    if (reading.notebook['is_notebook']) {
      setIsNotes(true)
    }
  }, [reading]);

  return (
    <Style.ItemCss>

      <Style.Description>
        <LittleDescription reading={reading.reading.data} />
      </Style.Description>

      <Style.AllButton>
        <ToggleStarButton reading={reading} updateStarStatus={updateStarStatus} />

        {isNotes ?
          <StandardLink
            fontSize={16}
            label={STATIC_VAR.NOTE}
            colorLink='#31e658'
            path={'/myNotebook/' + reading?.notebook.id} />
          :
          <StandardLink
            fontSize={16}
            label={STATIC_VAR.NOTE}
            path={'/myNotebook/' + reading?.notebook.id} />
        }
        <DeleteBinButton onClick={() => setReadingDelete(reading)} />
      </Style.AllButton>

    </Style.ItemCss>
  );
};

type Props = {
  reading: ReadingLibrary_m2m;
  updateStarStatus?: any;
  setReadingDelete?: any;
}
