import axios from 'axios';

let baseURL = ''
if (window.location.origin === "http://localhost:3000") {
    baseURL = "http://localhost:8000/api";
} else {
    baseURL = window.location.origin + '/api';
}

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        'Authorization': localStorage.getItem('access_token') ? "JWT " + localStorage.getItem('access_token') : null,
        'Content-type': 'application/json',
        'accept': 'application/json',
    }
})

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        // Prevent infinite loops
        if (error.response.status === 401 && error.response.data.detail === 'No active account found with the given credentials') {
            return Promise.reject(error);
        }
        if (error.response.status === 401 && originalRequest.url === 'user-profile/token/refresh/') {
            window.location.href = '/connect/';
            return Promise.reject(error);
        }
        if (error.response.status === 401 && originalRequest.url === baseURL + 'user-profile/token/refresh/') {
            window.location.href = '/connect/';
            return Promise.reject(error);
        }
        
        if (error.response.data.code === "token_not_valid" &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized") {
            const refreshToken = localStorage.getItem('refresh_token')
            
            if (refreshToken) {
                const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);
                
                if (tokenParts.exp > now) {
                    return axiosInstance
                    .post('user-profile/token/refresh/', { refresh: refreshToken })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access);
                        localStorage.setItem('refresh_token', response.data.refresh);
                        axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                        originalRequest.headers['Authorization'] = "JWT " + response.data.access;
                        return axiosInstance(originalRequest);
                    })
                    .catch(err => { console.log(err) });
                } else {
                    window.location.href = '/connect/';
                }
            } else {
                window.location.href = '/connect/';
            }
        }
        return Promise.reject(error.response)
    }
)
export default axiosInstance