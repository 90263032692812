import styled from 'styled-components'

export const LabelInputFile = styled.label`
  font-size: 23px;
  font-weight: 300;
  color: white;
  border: solid 1px;
  border-color: rgb(202, 109, 92);
  border-radius: 5px;
  color: rgba(202, 109, 92); 
  padding: 8px;
  margin: 8px;
  :hover {
    color: white; 
    background-color: rgb(202, 109, 92);
    cursor: pointer;
  }
`;
export const InputFile = styled.input`
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: none;
`;
export const ImageUpload = styled.img`
  width: 20%;
  margin: 8px;
`;
export const ErrorMessage = styled.div`
  color: rgb(202, 109, 92);
  font-size:21px;
  font-weight: 490;
`;
