import { useState } from 'react';

import { TextFieldBasic, InputButton, HiddenTextField } from '@components/forms'
import UserProfileService from "@services/userProfileService";

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.authentification

const INITIAL_STATE = {
  username: '',
  email: '',
  password: '',
  passwordConfirm: '',
  errors: {}
}

export const Signup = ({
  setAccountCreate
}) => {

  const [signupForm, setSignupForm] = useState(INITIAL_STATE);

  const handleChange = (event) => {
    setSignupForm({ ...signupForm, [event.target.id]: event.target.value, });
  };

  const check_form = (signupForm) => {
    for (const [key, value] of Object.entries(signupForm)) {
      if (typeof value === "string" && value.length === 0) {
          setSignupForm({
          ...signupForm,
          errors: {[key]: 'This field may not be blank.'},
        });
        return false
      }
    }
    return true
  }

  const submitForm = (event) => {
    event.preventDefault();
    if (!check_form(signupForm)) return
    UserProfileService.create(signupForm).then((response) => {
      if (response.status === 201 && response.statusText === ('Created')) {
        setAccountCreate(true)
      } else {
        setSignupForm({ ...signupForm, errors: response.data });
      }
    }).catch( error => {
        setSignupForm({ ...signupForm, errors: error.response.data, });
      })
      event.preventDefault()
  }
  return (
    <div style={{ width: '100%' }}>
      <form>
        <TextFieldBasic
          label={STATIC_VAR.USERNAME}
          id="username"
          value={signupForm.username}
          onChange={handleChange} />
        <div style={{ color: 'red' }}>
          {signupForm.errors['username'] ? signupForm.errors['username'] : null}
        </div>

        <TextFieldBasic
          label={STATIC_VAR.EMAIL}
          id="email"
          value={signupForm.email}
          onChange={handleChange} />
        <div style={{ color: 'red' }}>
          {signupForm.errors['email'] ? signupForm.errors['email'] : null}
        </div>

        <HiddenTextField id='password' label={STATIC_VAR.PASSWORD} value={signupForm.password} onChange={handleChange} />
        <div style={{ color: 'red' }}>
          {signupForm.errors['password'] ? signupForm.errors['password'] : null}
        </div>

        <HiddenTextField id='passwordConfirm' label={STATIC_VAR.PASSWORD2} value={signupForm.passwordConfirm} onChange={handleChange} />

        <InputButton label={STATIC_VAR.SIGNUP} onClick={submitForm} />
      </form>
    </div>
  )
}
