import { BsThreeDots } from "react-icons/bs"
import { MouseEventHandler } from 'react'
import styled from "styled-components";

import { GenericPointerButton, sizeIcon } from '@styles/genericStyleButton'
export const ThreeDotsDropDown = styled.div`
position: absolute;
top: 100%;
left: 0;
// width: 300px;
z-index: 2;
border: 1px solid rgba(0, 0, 0, 0.04);
box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
`;
export const ThreeDotsDropDownUl = styled.ul`
list-style: none;
padding: 0;
margin: 0;
&:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
`;
export const ThreeDotsDropDownLi = styled.ul`
padding: 8px 12px;
`;


type Props = {
  onClick: MouseEventHandler,
}

export const ThreeDotsDropdownButton = ({
  onClick,
}: Props) => {
  return (
    <GenericPointerButton>
      <BsThreeDots size={sizeIcon} onClick={onClick} />
      <ThreeDotsDropDown>
        <ThreeDotsDropDownUl>
          <ThreeDotsDropDownLi>Option 1</ThreeDotsDropDownLi>
          <ThreeDotsDropDownLi>Option 2</ThreeDotsDropDownLi>
          <ThreeDotsDropDownLi>Option 3</ThreeDotsDropDownLi>
          <ThreeDotsDropDownLi>Option 4</ThreeDotsDropDownLi>
        </ThreeDotsDropDownUl>
      </ThreeDotsDropDown>
    </GenericPointerButton>
  )
}
