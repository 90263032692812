import { useState } from 'react';
import styled from "styled-components";

import { StandardLink } from '@components/links'
import UserProfileService from "@services/userProfileService";
import { GenericButton } from '@components/buttons';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.connect

export const Text = styled.div`
  font-size: 30px;
  font-weight: 350px;
  text-align: center;
  padding: 10px;
  margin-top:20px;
  font-size: 23px;
  font-weight: 350px;
  outline:none; 
  background-color: rgba(236,236,236,0.8);
  color: rgb(202, 109, 92);
`;

export const ActivateAccount = () => {
  const [messageToUser, setMessageToUser] = useState('')

  const clickTConfirmEmail = () => {
    const params = window.location.toString().slice(window.location.toString().indexOf('?'));
    UserProfileService.confirmEmail(params).then((
      response) => {
      setMessageToUser(response.data)
    }).catch(
      error => {
        setMessageToUser(error)
      })
  }
  return (
    <Text>
      {messageToUser ?
        <StandardLink path={'/connect'} label={STATIC_VAR.ACCOUNT_ACTIVE} />
        :
        <GenericButton label="Confirm email" onClick={() => clickTConfirmEmail()}></GenericButton>
      }
    </Text>
  )
}

