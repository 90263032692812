import axiosInstance from '@services/api'

const baseUrlv1 = '/user-profile/v1/';
// const baseUrl = 'user-profile/';

class UsersService{

    getAllUser  = () => {
        const url = baseUrlv1 + `users`
        return axiosInstance.get(url).then((res) => {
            return res
        }).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };

    getFilteredUsers = (params) => {
        const url = `/user-profile/v1/search-users\?username__contains=${params}`;
        return axiosInstance.get(url).catch(err => {
            console.log('Error: ', err)
            return err
          });
    };
}

export default new UsersService();
