import { Link } from "react-router-dom";
import styled from "styled-components";

const NavLink = styled(Link)`
  margin-left: 5px;
  margin-right: 5px;
  font-weight:555;
  // color: #7b7b7b;
  text-decoration: none;
  &:hover {
    color: rgba(202, 109, 92, 1);
  }
`;

type Props = {
    path: string,
    label?: string,
    params?: {},
    fontSize?: number,
    colorLink?: string,
}

export const StandardLink = ({
  path,
  label,
  params,
  fontSize,
  colorLink
}: Props) => {
  return (
    <NavLink style={{fontSize: fontSize, color: colorLink ? colorLink:'#7b7b7b'}} to={path} state={params}>{label}</NavLink>
  )
}

