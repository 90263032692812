import NotificationService from "@services/notificationService";


export const getListNotification = (setListNotif) => {
    NotificationService.listService().then((res) => {
        setListNotif(res['data']['results'])
    }).catch(error => console.log('error - getListNotification', error))

};

export const deleteNotification = (index, listNotif, setListNotif) => {
    NotificationService.deleteService(listNotif[index]['id']).then((res) => {
        const result = Array.from(listNotif);
        result.splice(index, 1)
        setListNotif(result)
    }).catch(error => console.log('error - getListNotification', error))
};
