import * as Style from './instruction.style';
import { StandardLink } from '@components/links'
import { RiDeleteBin5Line } from "react-icons/ri";

export const Instruction = () => {

  return (
    <Style.GridMain>
      <Style.TitleText>Questions fréquemment posées 👀</Style.TitleText>

      <Style.Section>
        <Style.Question>Comment ajouter un livre ?  </Style.Question>
        <Style.Response>
          - Tapez dans la barre de recherche le nom du livre ou de l'auteur que vous recherchez.<br />
          - Une fois trouvé ce qui vous intéresse cliquez sur « + ».<br />
          - Une fenêtre va s'ouvrir, choisissez ou créez la catégorie dans laquelle vous voulez l'ajouter puis cliquez sur « Enregistrer ».<br />
          - Pour déplacer un livre, cliquez dessus puis « glissez - déposer » le où vous souhaitez. N'oubliez pas de cliquez sur « Enregistrer » qui apparait au dessus de la bibliothèque.
        </Style.Response>
      </Style.Section>


      <Style.Section>
        <Style.Question>Les fonctionnalités d'un livre de votre bibliothèque  </Style.Question>
        <Style.Response>
          - Cliquez sur le titre du livre pour lire le résumé.<br />
          - Cliquez sur ⭐ pour indiquer que c'est un de vos livres préférés.<br />
          - Cliquez sur « Notes » pour écrire vos commentaires sur ce livre, et n'oubliez pas de les « Enregistrer ».<br />
          - Cliquez sur <RiDeleteBin5Line size={15} /> pour supprimer le livre.
        </Style.Response>
      </Style.Section>


      <Style.Section>
        <Style.Question>Comment renommer ou supprimer une catégorie ? </Style.Question>
        <Style.Response>
          - Pour renommer une catégorie, cliquez sur le titre, une case dans laquelle vous pouvez écrire apparaîtra, une fois les modifications faites cliquez sur le bouton « Enregistrer ».<br />
          - Pour supprimer une catégorie, celle-ci doit être vide. Cliquez sur le bouton à droite « ⁝ » puis « Supprimer ».
        </Style.Response>
      </Style.Section>

      <Style.Section>
        <Style.Question>Comment suivre un.e autre utilisateurice ? </Style.Question>
        <Style.Response>
          - Sur votre droite se trouve la barre de recherche «  Utilisateurice » , tapez l'identifiant de la personne recherchée. <br />
          - Cliquez sur celui-ci pour voir sa bibliothèque et cliquez sur «  Suivre » pour recevoir en notifications ses nouveaux livres enregistrés.<br />
          - Pour découvrir les bibliothèques d'autres utilisateurices , il vous suffit de taper une première lettre et de nouveaux identifiants apparaitront.
        </Style.Response>
      </Style.Section>

      <Style.Section2 >
        <Style.Question>Si vous avez d'autres questions </Style.Question>
        <StandardLink path={'/contact'} label='--> Contactez nous <--' fontSize={20} />
      </Style.Section2>

    </Style.GridMain>
  )
}

