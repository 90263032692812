import { MouseEventHandler } from 'react';
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";

import { GenericPointerButton } from '@styles/genericStyleButton';

type Props = {
  onClick: MouseEventHandler,
}

export const LeftButton = ({
  onClick,
}: Props) => {
  return (
    <GenericPointerButton>
      <BsArrowLeftCircle size={'15px'} onClick={onClick} />
    </GenericPointerButton>
  )
};

export const RightButton = ({
  onClick,
}: Props) => {
  return (
    <GenericPointerButton>
      <BsArrowRightCircle size={'15px'} onClick={onClick} />

    </GenericPointerButton>
  )
};
