import { DraggableLocation } from "react-beautiful-dnd";

import { Library } from '@types_app/index';

export const addCategory = (
  name: string,
  library: Library,
  setLibrary: any,
) => {
  let categories = Array.from(library.categorylibrary_m2m_set);
  const order = library.categorylibrary_m2m_set.length > 0 ? library.categorylibrary_m2m_set[0].order + 1 : 0;
  const newCategory = { order: order, name: name, readinglibrary_m2m_set: [] }
  // const newCategory = { id: -order, order: order, name: name, readinglibrary_m2m_set: [] }
  categories.unshift(newCategory)
  setLibrary({ ...library, 'categorylibrary_m2m_set': categories })
  return newCategory
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const reorderCategories = (
  list: any[],
  startIndex: number,
  endIndex: number,
): any[] => {
  const tmp = list[startIndex].order
  list[startIndex].order = list[endIndex].order;
  list[endIndex].order = tmp;
  return reorder(list, startIndex, endIndex)
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const reorder = (
  list: any[],
  startIndex: number,
  endIndex: number,
): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const reorderRows = (
  library: Library,
  source: DraggableLocation,
  destination: DraggableLocation,
) => {
  const current = library.categorylibrary_m2m_set.find(x => x.id.toString() === source.droppableId);
  const next = library.categorylibrary_m2m_set.find(x => x.id.toString() === destination.droppableId);
  if (current && next) {
    const target = current.readinglibrary_m2m_set[source.index];

    //   // moving to same list
    if (source.droppableId === destination.droppableId) {
      const reordered = reorder(current.readinglibrary_m2m_set, source.index, destination.index);

      reordered.slice().reverse().forEach((element, index) => {
        if (element.order !== index) element.order = index
      })
      const reoderobj = { ...current, 'readinglibrary_m2m_set': reordered }
      const newCategories = library.categorylibrary_m2m_set.map(x => (x.id === current.id ? reoderobj : x))
      return { ...library, 'categorylibrary_m2m_set': newCategories };
    }

    // moving to different list
    // remove from original
    current.readinglibrary_m2m_set.splice(source.index, 1);
    current.readinglibrary_m2m_set.slice().reverse().forEach((element, index) => {
      if (element.order !== index) {
        element.order = index
      }
    })
    // insert into next
    next.readinglibrary_m2m_set.splice(destination.index, 0, target);
    next.readinglibrary_m2m_set.slice().reverse().forEach((element, index) => {
      if (element.order !== index) {
        element.order = index
      }
    })
    return {
      ...library,
      'categorylibrary_m2m_set': library.categorylibrary_m2m_set.map(x => {
        if (current.id === x.id) {
          return { ...x, 'readinglibrary_m2m_set': current.readinglibrary_m2m_set };
        }
        else if (next.id === x.id) {
          return { ...x, 'readinglibrary_m2m_set': next.readinglibrary_m2m_set };
        }
        return x;
      })
    };
  }
};