import styled from 'styled-components';

import { MouseEventHandler } from 'react';

type Props = {
  label: String,
  onClick: MouseEventHandler,
}

export const BntEx = styled.div`
  color: white;
  cursor: pointer;
  border-radius: 20px;
  padding: 1px 20px 5px 20px;
  background-color: rgb(202, 109, 92);
  :hover {
    color: rgb(117, 197, 225);
  }
`;

export const RedGenericButton = ({
    label,
    onClick,
  }: Props) => {
    return (
      <div> <BntEx onClick={onClick}>{label}</BntEx></div>
    )
  }

  