import { MouseEventHandler } from 'react';
import { RiDeleteBin5Line } from "react-icons/ri";

import { GenericPointerButton } from '@styles/genericStyleButton';


type Props = {
  onClick: MouseEventHandler,
}

export const DeleteBinButton = ({
    onClick,
  }: Props) => {
    return (
        <GenericPointerButton>
          <RiDeleteBin5Line size={15} onClick={onClick}/>
        </GenericPointerButton>
    )
  }
  