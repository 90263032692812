import styled from "styled-components";

export const TitleText = styled.div`
  color: rgb(54,55,54);
  text-align: center;
  font-size:47px;
  font-weight: 190;
  margin-top: 5px;
`;
export const Box = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 25px;
`;
  export const ColorBox = styled.div`
  padding: 20px;
  margin: 0px 15px 0px 15px;
  font-size:23px;
  font-weight: 590;
  border: solid 1px;
  border-radius: 10px;
  border-color: rgb(226, 150, 95);
`;
export const Question = styled.div`
  font-size:37px;
  font-weight: 190;
  border-bottom: dashed 1px;
  color: rgb(202, 109, 92);
`;
export const Response = styled.div`
  margin:5px;
  margin-top:15px;
  font-size:22px;
  font-weight: 390;
`;

