import styled from 'styled-components'

export const Main= styled.div`
  margin-left: 1.5%;
  margin-right: 1.5%;

`;
export const MainNotebook = styled.div`
  display: flex;
  justify-content: center;
`;
export const HeadNotebook = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom:10px;
  border-bottom: 1px solid;
  border-color: rgba(202, 109, 92, 0.3);
`;
export const Description = styled.div`
  width:25%;
  margin-right: 40px;
`;
export const BodyNotebook = styled.div`
  border: 2px solid;
  border-bottom: 0px solid;
  border-color: rgba(202, 109, 92, 0.3);
  width:65%;
  padding: 5px;
  // background-color: #f2f2f2;

`;
export const TextDescription = styled.div`
  min-height: 38px;
  padding-left:15px;
  padding-right:15px;
  font-size: 19px;
  font-weight: 380;
  padding-bottom:12px;
`;