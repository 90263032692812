import axiosInstance from '@services/api'

class BooksApiService{

    getSuggestionList = (params) => {
        const url = `google-books-api/suggestion-list/?q=${params}`
        return axiosInstance.get(url);
    };
    getbook = () => {
        const url = `google-books-api/v1/book-detail/`
        return axiosInstance.get(url);
    };
}

export default new BooksApiService();
