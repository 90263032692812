import { SmallSizeNotif } from '@components/notification';
import * as Style from './notification.style'
import { SearchBar, SearchBarUser } from '@components/searchBar';
import { MenuHeadBar } from '@components/menu';


export const Notification = () => {

  return (
    <Style.Main>
            <MenuHeadBar />
      <SearchBarUser />
      <SmallSizeNotif />
    </Style.Main>
  )
}
