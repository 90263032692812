import styled from "styled-components";

export const DragAndDropArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: center;
`;
export const WhiteRowBackgroundCss = styled.div`
  padding: 0px 15px 0px 5px;
  border-radius: 3px;
  overflow: auto;
  min-width: 290px;
  max-width: 30%;
  margin-bottom: 15px;
`;
export const HeaderReadingList = styled.div`
  display: flex;
  background-color: rgba(236,236,236,0.8);
  border-radius: 4px;
  position: relative;
  align-items: center;
  margin-bottom: 3px;
`;
export const Txt = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

export const DivChevron = styled.div`
  display: flex;
  justify-content: center;
`;
