import { BsPersonCircle } from "react-icons/bs";

import { Menu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { StandardLink } from '@components/links'

import { Logout } from '@features/authentication'

import * as Style from './menuHeadBar.style'

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.menu.menuHeadBar

const HeadList = () => {
  return (
    <Style.HeadListElement >
      <div> <StandardLink path={'/'} label={STATIC_VAR.My_LIBRARY} fontSize={20} /> </div>
      {/* <div> <StandardLink path={'/settings'} label={STATIC_VAR.PARAMS} fontSize={20} /> </div> */}
      <div> <StandardLink path={'/notification'} label={STATIC_VAR.NOTIFICATION} fontSize={20} /> </div>
      <div> <StandardLink path={'/contact'} label={STATIC_VAR.REPPORT_BUG} fontSize={20} /> </div>
      <div> <StandardLink path={'/instruction'} label={STATIC_VAR.INSTRUCTION} fontSize={20} /> </div>
      <Logout />
    </Style.HeadListElement>
  )
}

export const MenuHeadBar = () => {
  return (
    <Style.AllMenuButton>
      <Menu menuButton={
        <Style.MenuButton2>
          <Style.UserName>
          <BsPersonCircle size="25px" />
            {localStorage.getItem('user_name')}
          </Style.UserName>
        </Style.MenuButton2>} transition>
        <HeadList />
      </Menu>
    </Style.AllMenuButton>
  )
}
