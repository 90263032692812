import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { GenericButton } from '@components/buttons';
import { GenericPointerButton } from '@styles/genericStyleButton';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.buttons

type Props = {
  onClick: MouseEventHandler,
}
export const BntEx = styled.div`
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  padding: 1px 10px 2px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;

  :hover {
    font-size:14px;
  }
`;


export const FollowButton = ({
  onClick,
}: Props) => {
  return (
    <div>
      <BntEx onClick={onClick} color="rgb(117, 197, 225)" >{STATIC_VAR.FOLLOW} </BntEx>
    </div>
  )
}


export const UnfollowButton = ({
  onClick,
}: Props) => {
  return (
    <div>
      <BntEx onClick={onClick} color="rgb(202, 109, 92)" >{STATIC_VAR.UNFOLLOW} </BntEx>
    </div>
  )
}

