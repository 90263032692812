import * as Style from './categorySummary.style';

import { UnderlineText } from '@components/text';

import { Library } from '@types_app/index';

type Props = {
  library: Library;
};

export const CategorySummary = ({
  library,
}: Props) => {

  const scrollTo = (rowName: any) => {
    var element = document.getElementById(rowName);
    element.scrollIntoView();
  }

  return (
    <Style.GridMain>

      {library?.categorylibrary_m2m_set.map((category, i) => (
        <Style.Clicable key={category.order}>
          <UnderlineText
            key={category.id}
            text={category.name + ' (' + category.readinglibrary_m2m_set.length + ')'}
            onClick={() => scrollTo(category.name)} />
        </Style.Clicable>
      ))}
    </Style.GridMain>
  );
};
