import { Link } from "react-router-dom";
import styled from "styled-components";

const NavLink = styled(Link)`
  margin:6px;
  padding: 1px 11px 3px 12px;
  color: rgba(280, 200, 190, 1);
  background-color: rgb(117, 197, 225);
  border-radius: 10px;
  color: white;
  font-weight: 520;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    font-weight: bold;
    color: rgba(202, 109, 92, 1);
    text-decoration: none;
  }
`;
type Props = {
  path: string,
  label: string,
}

export const SimpleLink = ({
  path,
  label,
}: Props) => {
  return (
    <NavLink to={path}>{label}</NavLink>
  )
}

