import styled from 'styled-components';

import { Capitalize } from '@utils';

export const DisplayedText = styled.div`
  min-height: 38px;
  padding-left:5px;
  padding-right:5px;
  border-bottom: 2px dashed rgb(202, 109, 92);
  margin: 7px 0px 10px 5px;
  font-size:18px;
  font-weight: 320;
`;

type Props = {
  text: String,
  onClick?: () => void,
}

export const UnderlineText = ({
  text,
  onClick,
}: Props) => {
  return (
    <DisplayedText onClick={onClick}> {Capitalize(text)}&#160; </DisplayedText>
  )
};
