import styled from "styled-components";

export const BookList = styled.ul`
  position: absolute;
  z-index:50;
  background-color: white;
  width: 60%;
  margin-left: 30px ;
  border-radius:3px;
  box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.2);
  list-style-type: none;
  padding-left:0px;
`;
export const AllWindow = styled.ul`
  position: fixed;
	top: 230px;
	left: 0;
	height: 100vh;
	width:90vw;
  z-index:49;
  background-color: rgba(255,255,255,0.5);
  width: 100%;
`;
export const BookPropositionElement = styled.li`
  text-decoration: none;
  border-bottom: 1px solid rgba(211, 220, 50, .6);;
  padding:5px;
  &:hover {
    background-color: rgba(171, 110, 150, .6);
  }
`;
export const ImgPropositionElement = styled.img`
  position: relative;
  padding-left:5px;
  width: 55px;
`;
export const LinkNotebook = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  `;
