import styled from "styled-components";

export const SearchInput = styled.input`
  display: block;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 30px;
  border-color: rgb(202,109,92);
  background-color: rgb(77,77,77);
  color: white;
  margin: 19px 45px 0px 0px;
`;
export const ResultSearch = styled.div`
  margin:3px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
`;
export const Element = styled.div`
  color: rgb(117, 197, 225);
  background-color: white;
  border-bottom: dashed 1px;
  padding: 5px;
  justify-content: space-between;
  display: flex;
`;


export const Main = styled.input`
  border-color: rgba(202,109,92,1);
`;


// export const SearchInput = styled.input`
//   position: absolute;
//   top:10px;
//   left:15%;
//   width: 40vw;
//   padding: 15px;
//   font-size: 17px;
//   border-radius: 30px;
//   border-color: rgb(202,109,92);
// `;
