import styled from "styled-components";


export const Main = styled.input`
  border-color: rgba(202,109,92,1);
`;


export const SearchInput = styled.input`
  position: absolute;
  top:10px;
  left:15%;
  width: 40vw;
  padding: 15px;
  font-size: 17px;
  border-radius: 30px;
  border-color: rgb(202,109,92);
`;
