import { useState } from 'react';

import { AccountCreated } from '@pages'
import { WelcomeText } from '@components/text';

import { Login, Signup, ForgotPassword } from '@features/authentication'

import * as Style from './connect.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.connect

export const Connect = () => {
  const [action, setAction] = useState<string>('1')
  const [accountCreated, setAccountCreated] = useState<boolean>(false)

  return (
    <Style.Main>
      <WelcomeText />
      <Style.ImageBox>
        <Style.Connect>
          {accountCreated ?
            <AccountCreated />
            :
            <>
              {action === '3' ?
                <>
                  <Style.SimpleLink onClick={() => setAction('1')}> retour </Style.SimpleLink>

                  <ForgotPassword />
                </>
                :
                <>
                  <Style.ToggleWindow>
                    <Style.BoxInput nth={action}>
                      <Style.StyledInput onClick={() => setAction('1')}>{STATIC_VAR.LOGIN}</Style.StyledInput>
                      <Style.StyledInput onClick={() => setAction('2')}>{STATIC_VAR.SIGN_UP}</Style.StyledInput>
                    </Style.BoxInput>
                  </Style.ToggleWindow>
                  {action === '1' ? <Login /> : <Signup setAccountCreate={setAccountCreated} />}

                  <Style.SimpleLink onClick={() => setAction('3')}>Mot de passe oublié ? 🤪</Style.SimpleLink>

                </>
              }
            </>
          }

        </Style.Connect>
        {/* <img height='30%' src={require('@assets/ladybook.png')} /> */}
      </Style.ImageBox>
      <Style.BottomPart>

        <Style.ImgLogo src={require('@assets/LOGOTYPE_LESEN_original_Plan.png')} />
        <Style.Response>
          Réseau Social Littéraire
        </Style.Response>
      </Style.BottomPart>

      {/* Créez votre propre bibliothèque numérique et classez vos livres par catégories selon votre choix. */}
      {/* Naviguer dans les bibliothèques des autres utilisateurices. */}
    </Style.Main>
  )
}
