import axiosInstance from '@services/api'

class NotebookService {

    getNotebookService = (id) => {
        return axiosInstance.get(`/notebook/v1/notebook/${id}/`).then((res) => {
            return res
        }).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };
    createNotebookService = (data) => {
        const url = `/notebook/v1/notebook-manage/`
        return axiosInstance.post(url, data).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };
    updateNotebookService = (id, data) => {
        const url = `/notebook/v1/notebook-update/${id}/`
        return axiosInstance.patch(url, data).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };

}

export default new NotebookService();
