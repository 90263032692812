import styled from 'styled-components';

import { Capitalize } from '@utils';

export const DisplayedText = styled.div`
  min-height: 30px;
  padding-left:5px;
  padding-right:5px;
  margin: 7px 0px 10px 5px;
  font-size:15px;
  font-weight: 320;
`;

type Props = {
  text: String,
  onClick?: () => void,
}

export const SimpleText = ({
  text,
  onClick,
}: Props) => {
  return (
    <DisplayedText onClick={onClick}> {Capitalize(text)}&#160; </DisplayedText>
  )
}
