import styled from "styled-components";

export const GridMain = styled.div`
  display: flex;
`;
export const Page = styled.div`
  width: 98%;
`;
  export const Proposition = styled.div`
  padding: 5px;
`;
  
