import axiosInstance from '@services/api'

class FollowedService{

    get  = (user_id) => {
        const url = `followed/v1/followed/${user_id}/`
        return axiosInstance.get(url).then((res) => {
            return res
        }).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };
    update  = (data, user_id) => {
        const url = `followed/v1/followed-update/${user_id}/`
        return axiosInstance.put(url, data).then((res) => {
            return res
        }).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };
            
}

export default new FollowedService();
