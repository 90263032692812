import styled from 'styled-components'

export const Head = styled.div`
  display: flex;
`;
export const HeadNotebook = styled.div`
  margin: 8px auto 8px auto;
`;
export const ButtonDiv= styled.div`
  align-items: right;
  margin-top: auto;
  margin-bottom: auto;
`;
export const Notes= styled.div`
  background-color: rgb(255, 255, 255);
  font-size: 16px;
`;