import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'
import { MenuButton, } from '@szhsin/react-menu';


export const AllMenuButton = styled.div`
`;

export const MenuButton2 = styled(MenuButton)`
  background: none;
  cursor: pointer;
  border: None;
  border-radius: 4px;
`;

export const NavLink = styled(Link)`
  posision: relative;
  color: #808080;
  display: flex;
  text-decoration: none;
  cursor: pointer;
`;


export const HeadListElement = styled.div`
  posision: relative;
  color: #808080;
  text-decoration: none;
  cursor: pointer;
`;
export const MenuOption = styled.ul`
// padding: 8px 12px;
display:flex;
:hover {
  color: rgba(202, 109, 92, 1);
}
`;