import { useState, useEffect } from 'react';

import { StandardLink } from '@components/links';
import { DeleteBinButton } from '@components/buttons'

import { splitDate } from '@utils';
import { getListNotification, deleteNotification } from '@utils/notificationUtils';

import * as Style from "./smallSizeNotif.style";

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.notification.smallSizeMotif

export const SmallSizeNotif = () => {

  const [listNotif, setListNotif] = useState(null);

  useEffect(() => {
    getListNotification(setListNotif)
  }, []);

  return (
    <div>
      <Style.HeaderCss> {STATIC_VAR.NOTIFICATION} </Style.HeaderCss>

      <Style.ContainerNotifCss>
        {listNotif?.map((value, index) => (
          <Style.NotifCss key={index}>
            <Style.TextCss>💡
              <em>
                <StandardLink
                  path={'/library/' + value['reader']['id']}
                  label={value['reader']['username']}
                />
              </em>&#160;{STATIC_VAR.ADD_TO}&#160;<em>
                <StandardLink
                  path={'/reading/show/'
                    + value['reading']['source'] + '/'
                    + value['reading']['idFromSource']
                  }
                  params={{ reading: value['reading']['data'] }}
                  label={value['reading']['data']['volumeInfo']['title']}
                />
              </em>
              &#160; de&#160;
              {value['reading']['data']['volumeInfo']['authors']}&#160;
              ({splitDate(value['reading']['data']['volumeInfo']['publishedDate'])})
            </Style.TextCss>

            <Style.ButtonCss>
              <DeleteBinButton onClick={() => deleteNotification(index, listNotif, setListNotif)} />
            </Style.ButtonCss>
          </Style.NotifCss>
        ))}
      </Style.ContainerNotifCss>
    </div>
  )
}


