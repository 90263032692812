import Fuse from 'fuse.js'

import { useState, useEffect, useRef } from 'react';
import { AddToLibraryModal } from '@components/modals';

import BooksApiService from "@services/booksApiService";
import { DropdownListLib } from '@components/reading/listReading';

import { Library } from '@types_app/index';

import * as Style from "../searchBar.style";
import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.searchBar

type Props = {
  library: Library,
}

export const SearchInMyLib = ({
  library,
}: Props) => {

  const [inputValue, setInputValue] = useState('');
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [toSearchList, setToSearchList] = useState([]);
  const refOne = useRef(null)
  const [readingToAdd, setReadingToAdd] = useState();

  useEffect(() => {
    if (library && toSearchList.length == 0) {
      const toAdd = []
      for (var i in library?.categorylibrary_m2m_set) {
        for (var j in library?.categorylibrary_m2m_set[i].readinglibrary_m2m_set) {
            toAdd.push(library?.categorylibrary_m2m_set[i].readinglibrary_m2m_set[j])
        }
      }
      setToSearchList(toAdd)
    }
  },[library]);


  // useEffect(() => {
  //   console.log('toSearchList', toSearchList)
  // },[toSearchList]);


  // useEffect(() => {
  //   console.log('library', library?.categorylibrary_m2m_set)
  //   if (library && toSearchList.length == 0) {
  //     console.log('all is null set list')
  //   }
  // },[inputValue]);

    useEffect(() => {
    if (inputValue.length > 0) {
      document.addEventListener('click', handleClickOutside, true);
    }
    // 👇️ remove the event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [inputValue]);

  const checkInLib = (lstCat) => {
    const toAdd = []
    for (var i in lstCat) {
      for (var j in lstCat[i].readinglibrary_m2m_set) {
        if (lstCat[i].readinglibrary_m2m_set[j].reading.data.volumeInfo.title.toLowerCase().includes(inputValue)) {
          toAdd.push(lstCat[i].readinglibrary_m2m_set[j])
        }

      }
    }
    setSuggestionsList(toAdd)
  }

  useEffect(() => {
    if (inputValue.length > 1) {
      checkInLib(library?.categorylibrary_m2m_set)
    }
  }, [inputValue]);

  const handleClickOutside = (e) => {
    if (!refOne.current.contains(e.target)) {
      closeDropdownList()
      document.removeEventListener('click', handleClickOutside, true);
    }
  };
  const closeDropdownList = () => {
    setInputValue('')
    setSuggestionsList([])
    setReadingToAdd(null)
  };



  return (
    <div className="suggestions-list" ref={refOne} style={{ borderColor: 'rgba(202,109,92,1)' }}>
      
      <Style.SearchInput
        className="my-lib-input"
        type="text"
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
        placeholder={STATIC_VAR.SEARCH_MY_BOOK} />
      {suggestionsList.length > 0 ?
        <DropdownListLib
          suggestions={suggestionsList} 
          />
        : <></>
      }
    </div>
  )
};


