import axiosInstance from '@services/api'

const baseUrl = '/library/v1/';

export class LibraryService {
    getLibraryTestService = (id) => {
        const url = baseUrl + `library-get/${id}/`
        return axiosInstance.get(url).catch(err => {
            console.log('Error: ', err)
            return err
        })
    };
    patchLibraryService = (id, data) => {
        const url = baseUrl + `library-patch/${id}/`
        return axiosInstance.patch(url, data).catch(err => {
            console.log('Error: ', err)
            return err
        })
    };
    saveLibraryChangesService = (id, data) => {
        const url = baseUrl + `library-multi-update/${id}/`
        return axiosInstance.patch(url, data).catch(err => {
            console.log('Error: ', err)
        })
    };
    updateReadingLibraryService = (id, data) => {
        const url = baseUrl + `reading-library-update/${id}/`
        return axiosInstance.patch(url, data).catch(err => {
            console.log('Error: ', err)
        })
    };
// ______________________________________________________________________
// ______________________________________________________________________
    
    renameCategoryService = (id, data) => {
        const url = baseUrl + `category-rename/${id}/`
        return axiosInstance.patch(url, data).catch(err => {
            console.log('Error: ', err)
            return null
        })
    };
    deleteCategoryService = (id) => {
        const url = baseUrl + `category-delete/${id}/`
        return axiosInstance.delete(url).catch(err => {
            console.log('Error: ', err)
            return 'Error'
        })
    };

// ______________________________________________________________________
// ______________________________________________________________________
    
    deleteReadingFromCategoryService = (id) => {
        const url = baseUrl + `reading-delete/${id}/`
        return axiosInstance.delete(url).catch(err => {
            console.log('Error: ', err)
            return err
          })
    };

    getSameReadingUserService = (id) => {
        const url = baseUrl + `reading-user-list`
        return axiosInstance.get(url,  { params: { reading: id } }).catch(err => {
            console.log('Error: ', err)
            return err
          })
    };

    getReadingService = (id, filter) => {
        // We send the id of the reading and search all the user who have read this reading
        const url = baseUrl + `reading/${id}/`
        // return axiosInstance.get(url,  { params: { reading: id } }).catch(err => {
        return axiosInstance.get(url, { params: filter}).catch(err => {
            console.log('Error: ', err)
            return err
          })
    };

    getOrCreateReading = (params) => {
        // We send the id of the reading and search all the user who have read this reading
        const url = baseUrl + `reading/`
        // return axiosInstance.get(url,  { params: { reading: id } }).catch(err => {
        return axiosInstance.get(url, { params: params }).catch(err => {
            console.log('Error: ', err)
            return err
          })
    };
}

export default new LibraryService();
