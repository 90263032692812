import LibraryService from '@services/libraryService';

import { Library, ReadingLibrary_m2m, categorylibrary_m2m_set } from '@types_app/index';

export const getLibrary = (id, setRows) => {
  LibraryService.getLibraryTestService(id).then((
    response: any) => {
    setRows(response.data)
  }).catch((error: any) => console.log('error', error))
};

export const saveLibraryChanges = (data) => {
  LibraryService.saveLibraryChangesService(localStorage.getItem('user_id'), data)
  .catch((error: any) => {
    console.log('error', error)
  })
};

export const updateReadingLibrary = (id, reading, category, library, setLibrary) => {
  LibraryService.updateReadingLibraryService(id, {star: !reading.star}).then((res) => {
    const newList = category.readinglibrary_m2m_set.map(x => (x.id === reading.id ? {...x, star: !reading.star} : x))
    const newCategorie = {...category, readinglibrary_m2m_set: newList }
    const newCategories = library.categorylibrary_m2m_set.map(x => ((x.id === newCategorie.id ? newCategorie : x)))
    setLibrary({...library, 'categorylibrary_m2m_set': newCategories})
  }).catch((error: any) => {
    console.log('error', error)
  })
};

export const patchLibrary = (data) => {
  LibraryService.patchLibraryService(localStorage.getItem('user_id'), data).catch((error: any) => {
    console.log('error', error)
  })
};

export const addNewReading = (categorySelect, reading, source , setLibrary) => {
  if (!categorySelect || categorySelect.name.length < 1) return "Select a Category";

  const data = {
    'categoryBook': {
      'name': categorySelect.name,
      'order': categorySelect.order
    },
    'reading': {
      'source': source,
      'idFromSource': reading.id,
      'data': reading,
      'order': categorySelect.readinglibrary_m2m_set.length,
    }
  }
  if ('id' in categorySelect) {
    data['categoryBook']['id'] = categorySelect.id
  }

  LibraryService.patchLibraryService(localStorage.getItem('user_id'), data
    ).then((res: any) => { if (res.data) setLibrary(res.data) })
};

// ______________________________________________________________________
// ______________________________________________________________________
    
export const deleteCategory = (
  library: Library,
  index: number,
  setLibrary: any
) => {
  const result = Array.from(library.categorylibrary_m2m_set);
  if (result[index].readinglibrary_m2m_set.length !== 0) return
  LibraryService.deleteCategoryService(result[index].id).then(res => {
    if (res !== 'Error') {
      result.splice(index, 1)
      setLibrary({...library, categorylibrary_m2m_set: result})
    }
  })
};

export const updateCategoryName = (
  category: categorylibrary_m2m_set,
  newcategory: string,
) => {
  if (category.name === newcategory) return category
  category.name = newcategory
  LibraryService.renameCategoryService(category.id, { 'name': newcategory })
  return category
};

// ______________________________________________________________________
// ______________________________________________________________________

export const getOrCreateReading = (
  params: {},
  setReading: any,
) => {
  LibraryService.getOrCreateReading(params).then(res => {
    if (res.data instanceof Array) {
      setReading(res.data[0])
    } else {
      setReading(res.data)
    }
  })
};

export const readingDeleteFromCategory = (
  reading: ReadingLibrary_m2m,
  library: Library,
  setLibrary: any,
) => {
  LibraryService.deleteReadingFromCategoryService(reading.id).then(res => {
    const current = library.categorylibrary_m2m_set.find(element => element.id === reading.CategoryLibrary_m2m)
    const indexOf = current.readinglibrary_m2m_set.findIndex(element => element.id === reading.id )
    current.readinglibrary_m2m_set.splice(indexOf, 1);
    const newcategories = library.categorylibrary_m2m_set.map(x => (x.id === current.id ? current : x));
    setLibrary({...library, categorylibrary_m2m_set: newcategories})
  })
};

export const getSameReadingUser = (
  readingId: number,
  setUserList: any,
) => {
  LibraryService.getSameReadingUserService(readingId).then(res => {
    setUserList(res.data)
  })
};



