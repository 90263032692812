import axiosInstance from '@services/api'

const baseUrlv1 = '/ticket/v1/';

class TicketingService{

    getTicketService  = () => {
        const url = baseUrlv1 + `ticket`
        return axiosInstance.get(url).then((res) => {
            return res
        }).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };
    createTicketService  = (data) => {
        const url = baseUrlv1 + `ticket`
        return axiosInstance.post(url, data).then((res) => {
            return res
        }).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };
    updateTicketService  = (data) => {
        const url = baseUrlv1 + `ticket-update`
        return axiosInstance.patch(url, data).then((res) => {
            return res
        }).catch(err => {
            console.log('Error: ', err)
            return err
        });
    };
}

export default new TicketingService();
