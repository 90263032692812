import styled from "styled-components";

export const StarActiveButtonCss = styled.div`
  color: rgb(246, 211, 110);
  cursor: pointer;
&:hover {
  size: 20;
}
`;
export const StarUnactiveButtonCss = styled.div`
  cursor: pointer;
  color: rgb(169,169,169);
`;
