import styled  from "styled-components";
import { MenuButton, } from '@szhsin/react-menu';


export const AllMenuButton = styled.div`
  position: absolute;
  top:0px;
  right:0%;
  display: flex;
  height: 50px;
  padding: 13px 18px 2px 18px;
`;
export const MenuButton2 = styled(MenuButton)`
  display:flex;
  cursor: pointer;
  padding-right: 10px;
  background: none;
  border-radius: 4px;
  border: None;
`;
export const HeadListElement = styled.div`
  posision: relative;
  color: #808080;
  text-decoration: none;
  cursor: pointer;
`;
export const UserName = styled.div`
  background: rgba(255,255,255, 0.9);
  font-size: 25px;
  padding: 8px;
  display:flex;
  align-items: center;
  border-radius: 4px;
  color: rgb(202, 109, 92);
  gap: 10px;
`;
