import TicketingService from '@services/ticketingService'

import { Ticket } from '@types_app/index';

export const getTicket = (
  setTicket: any,
) => {
  TicketingService.getTicketService().then((res: any) => {
    setTicket(res.data)
  })
};

export const createTicket = (
  ticket: Ticket,
  setTicket: any,
) => {
  setTicket([])
  return ticket
};

export const updateTicket = (
  ticket: Ticket,
  setTicket: any,
) => {
  setTicket([])
  return ticket
};
