import { reorderCategories } from '@utils/categoryUtils';

import { Library } from '@types_app/index';

export const onUp = (
  index: number,
  library: Library,
  setLibrary: any,
  setToUpdate: any,
  ) => {
    const categories = reorderCategories(library.categorylibrary_m2m_set, index, (index ? index - 1 : index))
    setLibrary({...library, categorylibrary_m2m_set: categories})
    setToUpdate(true)
}
export const onDown = (
  index: number,
  library: Library,
  setLibrary: any,
  setToUpdate: any,
  ) => {
    const categories = reorderCategories(library.categorylibrary_m2m_set, index, index + 1)
    setLibrary({...library, categorylibrary_m2m_set: categories})
    setToUpdate(true)
}
