import styled from "styled-components";

export const ItemCss = styled.div`
  border: solid 1px;
  border-radius: 4px;
  border-color: rgba(202,109,92,0.7);
  box-shadow: 2px 1px 5px #837e7d;
  margin-bottom: 7px;
  height: 95px;
  overflow:auto;
  display:flex;
  &:hover {
    background-color: rgba(163,190, 140,0.3);
  }
`;
export const AllButton = styled.div`
  text-align: center;
  padding: 3px;
  border-left: 1px solid;
  border-color: rgba(202,109,92,0.7);
  margin: auto;
`;
export const Description = styled.div`
  width: 90%;
`;