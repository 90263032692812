import { useState, useEffect } from 'react';

import { LittleDescription } from '@components/reading/singleReading';
import { ToggleStarButton } from '@components/buttons';
import { StandardLink } from '@components/links';

import { createNotebook } from '@utils/notebookUtils';

import { ReadingLibrary_m2m } from '@types_app/index';

import * as Style from './readingElement.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.reading.singleReading

export const ReadingElement = ({
  reading,
}: Props) => {
  const [displayNote, setDisplayNote] = useState(false)

  useEffect(() => {
    if (reading && reading.notebook == null) {
      createNotebook(reading)
    }
    if (reading.notebook['is_notebook']) {
      setDisplayNote(true)
    }
  }, [reading]);

  return (
    <Style.ItemCss>
      <Style.description>
      
      <LittleDescription reading={reading.reading.data} />
      </Style.description>

      <Style.AllButton>
        {reading.star ?
          <ToggleStarButton reading={reading} updateStarStatus={null} />
          : <></>
        }
        {displayNote ?
          <StandardLink
            label={STATIC_VAR.NOTE}
            path={'/notebook/' + reading?.notebook.id} 
            fontSize={15}/>
          : <></>
        }
      </Style.AllButton>
    </Style.ItemCss>
  );
};

type Props = {
  reading: ReadingLibrary_m2m;
  updateStarStatus?: any;
  setReadingDelete?: any;
}
