import styled from 'styled-components';

export const Main = styled.div`
  border-radius: 3px;
  margin: auto 5px;;
`;
export const ProfilPicture = styled.img`
  height: 30px;
  border-radius: 3px;
`;
