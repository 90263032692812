import styled from "styled-components";

const url = 'https://freesvg.org/img/ladybooksframe.png'
const url2 = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Painting_by_Julia_Colavita_20101016.7D.06826_SML_%285103242019%29.jpg/640px-Painting_by_Julia_Colavita_20101016.7D.06826_SML_%285103242019%29.jpg"

export const Main = styled.div`
  margin-right: 10%;
  margin-left: 10%;
`;
export const TitleText = styled.h1`
  color: rgb(54,55,54);
  text-align: center;
  padding-top: 11px;
  margin-bottom: 20px;
  font-size:47px;
  font-weight: 190;
`;

export const Box = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-around;
  margin: 15px;
`;
export const ColorBox = styled.div`
  padding: 20px;
  width: 32%;
  font-size:23px;
  font-weight: 590;
  border: solid 1px;
  border-radius: 10px;
  border-color: rgb(226, 150, 95);
`;
export const Question = styled.div`
  font-size:37px;
  font-weight: 190;
  border-bottom: dashed 1px;
  color: rgb(202, 109, 92);
`;
export const Response = styled.div`
  margin:5px;
  margin-top:15px;
  font-size:22px;
  font-weight: 390;

`;


export const ImageBox = styled.div`
justify-content: end;
display:flex;
margin: 5px;
background: linear-gradient(to left, rgba(255,255,255,0.7) 78%,
rgba(255,255,255,0.7)), url(${url2});
padding: 20px;
border-radius: 20px;
`;
export const Image = styled.img`
`;



// ___
// bleu : rgb(117, 197, 225)
// jaune : rgb(246, 211, 110)
// orange : rgb(226, 150, 95)
// rouge : rgb(202, 109, 92)
// violet : rgb(171, 110, 150)
// noir : rgb(54, 55, 54)
