import { HiOutlineChevronDoubleDown, HiOutlineChevronDoubleUp } from "react-icons/hi"
import { MouseEventHandler } from 'react'


type Props = {
  onClick: MouseEventHandler,
}

export const ChevronDoubleDown = ({
  onClick,
}: Props) => {
  return (
      <HiOutlineChevronDoubleDown  size={25} onClick={onClick}/>
  )
}

export const ChevronDoubleUp = ({
  onClick,
}: Props) => {
  return (
      <HiOutlineChevronDoubleUp  size={25} onClick={onClick}/>
  )
}

export const DisplayChevron = (sizeList, maxHeight, setMaxHeight) => {
  if (sizeList <= 3) 
    return (<></>);
  else if (maxHeight === 'none') 
    return (<ChevronDoubleUp onClick={() => setMaxHeight('310px')} />);
  else 
     return (<ChevronDoubleDown onClick={() => setMaxHeight('none')} />);
}