import { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { ReadingList } from "./readingList";

import { GenericModal } from '@components/modals';

import { reorderRows } from '@utils/categoryUtils';
import { onUp, onDown, deleteCategory, readingDeleteFromCategory } from '@utils/libraryUtils';

import { Library } from '@types_app/index';
import * as Style from './readingList.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.reading.listReading.dragAndDropList

export const DragAndDropList = ({
  library,
  setLibrary,
  toUpdate,
  setToUpdate,
  saveChanges,
}: PropsCategoryRow) => {

  const [readingDelete, setReadingDelete] = useState();

  const deleteRows = (index: number) => {
    if (toUpdate === true) {
      saveChanges()
      setToUpdate(false)
    }
    deleteCategory(library, index, setLibrary)
  }

  const ModalToDelete = () => {
    if (readingDelete) {
      return (
        <GenericModal
          text={STATIC_VAR.TEXT_DELETE}
          writing={readingDelete}
          setReadingDelete={setReadingDelete}
          callBack={() => readingDeleteFromCategory(readingDelete, library, setLibrary)}
        />
      )
    } else return (<></>)
  }

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (!destination) return
        setLibrary(reorderRows(library, source, destination));
        setToUpdate(true)
      }}
    >
      <Style.DragAndDropArea>
        {library?.categorylibrary_m2m_set.map((category, i) => (
          <ReadingList
            library={library}
            setLibrary={setLibrary}
            key={category.id}
            category={category}
            deleteRows={() => deleteRows(i)}
            setReadingDelete={setReadingDelete}
            onUp={() => onUp(i, library, setLibrary, setToUpdate)}
            onDown={() => onDown(i, library, setLibrary, setToUpdate)}
          />
        ))}
      </Style.DragAndDropArea>

      {ModalToDelete()}
    </DragDropContext>
  );
};

type PropsCategoryRow = {
  library: Library,
  setLibrary: any,
  toUpdate?: boolean;
  setToUpdate?: any;
  saveChanges?: any;
}
