import React from 'react';
import styled from "styled-components";

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.other

export const Main = styled.div`
  text-align:center;
  justify-content: center;
`;

export const NotFound = () => {
  return (
    <Main> 
      <h2>{STATIC_VAR.CODE_ERROR}</h2> 
      <h2>{STATIC_VAR.not_found}</h2> 
      </Main>
  );
}

// const url = 'https://live.staticflickr.com/3262/2593000541_d565cb5e28_w.jpg'