import { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { MenuDropdown } from '@components/menu';
import { EditTextInput } from '@components/forms';
import { DisplayChevron } from '@components/buttons';
import { DragAndDropElement } from '@components/reading/singleReading';

import { updateReadingLibrary } from '@utils/libraryUtils';
import { updateCategoryName } from '@utils/libraryUtils';

import { Library } from '@types_app/index';

import * as Style from './readingList.style';

export const ReadingList = ({
  library,
  setLibrary,
  category,
  deleteRows,
  setReadingDelete,
  onUp,
  onDown,
}: Props) => {

  const [maxHeight, setMaxHeight] = useState('315px');

  const updateStarStatus = (reading) => {
    updateReadingLibrary(reading.id, reading, category, library, setLibrary)
  };

  return (
    <Style.WhiteRowBackgroundCss>

      <Style.HeaderReadingList>
        <Style.Txt id={category.name}>
          <EditTextInput
            value={category.name}
            editInputHandler={(newcategory) => updateCategoryName(category, newcategory)} />
        </Style.Txt>
        <MenuDropdown onUp={onUp} onDown={onDown} deleteRows={deleteRows} />
      </Style.HeaderReadingList>


      <Droppable droppableId={category.id.toString()} type='CARD' direction='vertical' >
        { dropProvided => (
        <div style={{ height: 'auto', maxHeight: maxHeight, minHeight: '100px', overflow: 'auto' }} {...dropProvided.droppableProps} ref={dropProvided.innerRef} >
          { category?.readinglibrary_m2m_set.map((value, index) => (
            <Draggable draggableId={category.id + value.id.toString()} index={index} key={category.id + value.id.toString()} >
              { dragProvided => (
                <div {...dragProvided.dragHandleProps} {...dragProvided.draggableProps} ref={dragProvided.innerRef} >
                  <DragAndDropElement
                    reading={value}
                    updateStarStatus={updateStarStatus}
                    setReadingDelete={setReadingDelete} />
                </div>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </div>
        )}
      </Droppable>

      <Style.DivChevron>
        {DisplayChevron(category.readinglibrary_m2m_set.length, maxHeight, setMaxHeight)}
      </Style.DivChevron>
    </Style.WhiteRowBackgroundCss>
  );
};

type Props = {
  library: Library,
  setLibrary: any,
  category?: any;
  deleteRows?: () => void;
  setRows?: any;
  setReadingDelete?: any;
  onUp?: () => void;
  onDown?: () => void;
}