import { MouseEventHandler } from 'react';
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

import * as Style from './starButton.style'

type Props = {
  onClick: MouseEventHandler,
}

export const StarActiveButton = ({
  onClick,
}: Props) => {
  return (
    <Style.StarActiveButtonCss>
      <AiFillStar size={23} onClick={onClick} />
    </Style.StarActiveButtonCss>

  )
};

export const StarUnactiveButton = ({
  onClick,
}: Props) => {
  return (
    <Style.StarUnactiveButtonCss>
      <AiOutlineStar size={23} onClick={onClick} />
    </Style.StarUnactiveButtonCss>
  )
};

export const ToggleStarButton = ({
  reading,
  updateStarStatus,
}) => {
  return (
    <>
      {reading['star'] ?
        <StarActiveButton onClick={() => updateStarStatus(reading)} />
        :
        <StarUnactiveButton onClick={() => updateStarStatus(reading)} />
      }
    </>
  )
};

