import styled from "styled-components";

export const ImgTxt = styled.div`
  display: flex;
`;
export const ImgCss = styled.img`
  width:50px;
  max-height:80px;
  margin:8px;
  box-shadow: 3px 2px 3px #837e7d;
`;
export const TitleCss = styled.div`
  font-size: 20px;
  font-weight: 510;
  display: inline;
`;
export const SudtitleCss = styled.div`
  font-size: 15px;
  font-weight: 430;
  display: inline;
  font-style: italic;
`;
export const AuthorCss = styled.div`
  font-size: 17px;
  font-weight: 500;
`;
export const DateCss = styled.div`
  font-style: italic;
  display: inline;
`;
export const NoImg = styled.div`
  min-width: 50px;
  height: 75px;
  margin:8px;
  background-color: rgba(236,236,236,0.8);
  box-shadow: 3px 2px 3px #837e7d;
`;