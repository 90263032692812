import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  margin-top:1%;
  margin-left: 10%;
  width: 100%;
`;
export const UpdateSetting = styled.div`
  font-size: 14px;
  font-weight: 500;
  height: 35px;;
  border: solid 1px;
  border-color: rgb(202, 109, 92);
  border-radius: 5px;
  color: rgba(202, 109, 92); 
  padding: 5px;
  margin: 8px;
  :hover {
    color: white; 
    background-color: rgb(202, 109, 92);
    cursor: pointer;
  }
`;
export const Box = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;