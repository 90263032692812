import styled from "styled-components";

export const Book = styled.div`
  display: flex;
`;
export const ImgCss = styled.img`
  height:150px;
  margin-right:15px;
  box-shadow: 4px 3px 4px #837e7d;
`;
export const NoImg = styled.div`
  height: 150px;
  width: 108px;
  margin-right: 15px;
  background-color: rgba(236,236,236,0.8);
  box-shadow: 4px 3px 4px #837e7d;
`;
export const TitleCss = styled.div`
  font-size: 20px;
  font-weight: 510;
`;
export const SudtitleCss = styled.div`
  font-size: 15px;
  font-weight: 430;
  display: inline;
  font-style: italic;
`;
export const BookText = styled.div`
  font-size: 17px;
  font-weight: 500;
`;

export type StyledDescriptionProps = {
  maxHeightDescription: boolean;
}
export const Description = styled.div<StyledDescriptionProps>`
  text-align: justify;
  overflow: hidden;
  font-size: 18px;
  margin: 10px;
  max-height:${(props) => props.maxHeightDescription ? '200px' : 'none'};
`;
export const DivChevron = styled.div`
  display: flex;
  justify-content: center;
`;
