import { MouseEventHandler } from 'react';

import styled from "styled-components";

const SimpleButtonCss = styled.button`
  margin:6px;
  padding: 1px 11px 3px 12px;
  color: rgba(280, 200, 190, 1);
  background-color: rgb(117, 197, 225);
  border-radius: 10px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  text-decoration: none;

&:hover {
  font-weight: bold;
  color: rgba(202, 109, 92, 1);
  text-decoration: none;
}
`;
type Props = {
  label: string,
  onClick: MouseEventHandler,
}

export const SimpleButton = ({
  label,
  onClick,
}: Props) => {
  return (
    <SimpleButtonCss  onClick={onClick}> {label} </SimpleButtonCss>
  )
}

