import styled from 'styled-components'

export const TheButton = styled.div`
  visibility: hidden;
  margin-top:5px;
`;
export const DisplayText = styled.div`
  display: flex;
  font-size:18px;
  cursor: pointer;
`;

export type StyledTheTextProps = {
  fontSize?: string;
}
export const TheText = styled.div<StyledTheTextProps>`
  padding: 0px 5px 0px 5px;
  margin: 7px 0px 10px 5px;
  font-size:${(props) => props.fontSize  ? props.fontSize : '25px'};
  font-weight: 320;
`;
export const InputText = styled.div`
  margin-left: 5px;
  display: flex;
  padding:5px;
`;
export const InputFormText = styled.input`
  margin: 5px;
  border: none;
  font-size:20px;
  font-weight: 390;
`;
