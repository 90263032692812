import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  font-size:25px;
  font-weight: 320;
`;
export const Proposition = styled.div`
  position: relative;
  min-width: 15%;
`;
export const Library = styled.div`
  // justify-content: center;
`;
export const AllCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const Category = styled.div`
  padding: 0px 15px 0px 5px;
  border-radius: 3px;
  overflow: auto;
  min-width: 290px;
  max-width: 30%;
  margin-bottom: 15px;
`;
export const HeaderReadingList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 4px;
  background-color: rgba(236,236,236,0.8);
`;
export const DivChevron = styled.div`
  display: flex;
  justify-content: center;
`;
