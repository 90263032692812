import { useState } from 'react';
import { InputButton } from '@components/forms'

import * as Style from './editNumberInput.style';
import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.form

type Props = {
  value: number;
  applyChanges: any;
  fontSize?: string
}

export const EditNumberInput = ({
  value,
  applyChanges,
  fontSize,
}: Props) => {

  const [editValue, setEditValue] = useState<string>('');

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value);
  };

  const registerEdit = () => {
    applyChanges(editValue, 'postal_code')
  }

  return (
    <div>
      <Style.InputText>
        <Style.InputFormText type='number'
          value={editValue}
          onChange={inputHandler} />
          <InputButton label={STATIC_VAR.REGISTER} onClick={() => registerEdit()} />

      </Style.InputText>

    </div>
  )
}
