import NotebookService from "@services/notebookService";

export const getNotebookData = (
  id: string,
  setReadingId: any,
  setReading: any,
  setUser: any,
  setNotebook: any
) => {
  NotebookService.getNotebookService(id).then((res) => {
    console.log('res.data', res.data)
    console.log('res.data m2m', res.data['ReadingLibrary_m2m'])
    setReadingId(res.data['ReadingLibrary_m2m'].reading.id)
    setReading(res.data['ReadingLibrary_m2m'].reading)
    setUser(res.data.user)
    delete res.data['ReadingLibrary_m2m']
    setNotebook(res.data)
  }).catch(error => console.log('error', error))
};



export const createNotebook = (reading: any) => {
  NotebookService.createNotebookService(reading)
  .then((res) => {
  // row.readings[index]['notebookID'] = data.data['id']
  // LibraryService.updateSingleCategory(row.id, row).then((res: any) => {
  //   console.log('registerChanges _ res', res)
  // }).catch((error: any) => console.log('error', error))
  //   navigate(`/notebook/${data.data['id']}/`)
  }).catch(error => console.log('error', error))
};
