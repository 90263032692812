import { useNavigate } from 'react-router-dom'
import { useState } from 'react';

import UserProfileService from "@services/userProfileService";
import { TextFieldBasic, HiddenTextField, InputButton } from '@components/forms'
import { ErrorMessage } from '@components/message'
import { StandardLink } from '@components/links'

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.connect

type INITIAL_STATE = {
  username: string;
  password: string;
}

type INITIAL_STATE_ERROR = {
  username: string,
  password: string,
  global: string,
};

const VALIDATION = {
  username: [
    {
      isValid: (value) => !!value,
      message: 'Is required.',
    },
  ],
  password: [
    {
      isValid: (value) => !!value,
      message: 'Is required.',
    },
  ],
};

export const Login = () => {

  const [loginForm, setLoginForm] = useState<INITIAL_STATE>({username: '', password: ''});
  const [errors, setErrors] = useState<INITIAL_STATE_ERROR>({} as INITIAL_STATE_ERROR);

  const [errorFields, setErrorFields] = useState<{}>({});

  let navigate = useNavigate();

  const submitForm = (event) => {
    event.preventDefault();
    setErrorFields(getErrorFields(loginForm));
    const hasErrors = Object.values(errorFields).flat().length > 0;
    if (hasErrors) return;
    UserProfileService.token_obtain(loginForm).then((
      data) => {
      localStorage.setItem('access_token', data.data.access)
      localStorage.setItem('refresh_token', data.data.refresh)
      localStorage.setItem('user_id', data.data.user_id)
      localStorage.setItem('user_name', data.data.user_name)
      navigate(('/'))
    }).catch(
      error => {
        setLoginForm({} as INITIAL_STATE)
        setErrors({ ...errors, 'global': 'User or Password Not Valid2' })
        throw error
      })
  }
  const getErrorFields = (form: any) => {

    return Object.keys(form).reduce((acc, key) => {
      if (!VALIDATION[key]) return acc;

      const errorsPerField = VALIDATION[key]
        // get a list of potential errors for each field
        // by running through all the checks
        .map((validation) => ({
          isValid: validation.isValid(form[key]),
          message: validation.message,
        }))
        // only keep the errors
        .filter((errorPerField) => !errorPerField.isValid);

      return { ...acc, [key]: errorsPerField };
    }, {});

  }

  const handleChange = (event) => {
    setLoginForm({
      ...loginForm,
      [event.target.id]: event.target.value,
    });

  };

  return (
    <div style={{width: '100%'}}>
      <form >
        <TextFieldBasic id='username' label={STATIC_VAR.USERNAME} value={loginForm.username} onChange={handleChange} />
        {errorFields && errorFields['username']?.length ? (
          <ErrorMessage message={errorFields['username'][0]['message']} />
        ) : null}
        <HiddenTextField id='password' label={STATIC_VAR.PASSWORD} value={loginForm.password} onChange={handleChange} />
        {errorFields && errorFields['password']?.length ? (
          <ErrorMessage message={errorFields['password'][0]['message']} />
        ) : null}
        <ErrorMessage message={errors['global']} />
        <InputButton label={STATIC_VAR.LOGIN} onClick={submitForm} />
      </form>
    </div>
  )
}