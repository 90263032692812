import { AddButtonModal } from '@components/buttons';
import { LittleDescription } from '@components/reading/singleReading';

import * as Style from "./dropdownList.style";

export const DropdownList = ({
  suggestions,
  setReadingToAdd,
}: Props) => {

  return (
    <Style.BookList>
      {suggestions.map((suggestion, index) => (
        <Style.BookPropositionElement key={index}>
          <LittleDescription reading={suggestion} />
          <AddButtonModal onClick={() => setReadingToAdd(suggestion)} ></AddButtonModal>
        </Style.BookPropositionElement>
      ))}
    </Style.BookList>
  );
};

type Props = {
  suggestions: any[];
  setReadingToAdd: any;
}
