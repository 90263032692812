import styled from 'styled-components';

export interface ModelProps {
  showModal?: boolean;
}

export const Modal = styled.div`
	z-index: 160;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width:100vw;
	background: rgba(0,0,0,0.5);
`;
export const ModalMain = styled.div`
  padding:10px;
  position:fixed;
  background: white;
  width: 55%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
`;
export const HeaderCss = styled.div`
  border-bottom: 1px solid #e6e6e6;
  background-color: rgba(246,211,110,0.5);
  padding: 10px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  font-weight: 410;
`;
export const TextCss = styled.div`
  font-size: 20px;
  font-weight: 510;
  display: inline;
`;
export const ButtonsCss = styled.div`
  display: flex;
  justify-content: end;
`;
