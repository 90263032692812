import { MouseEventHandler } from 'react';

import { BntEx } from './generic.style';

type Props = {
  label: string,
  onClick: MouseEventHandler,
  buttonColor?: string,
  fontSize?: string,
}

export const GenericButton = ({
  label,
  onClick,
  buttonColor,
  fontSize
}: Props) => {
  return (
    <div>
      <BntEx
        color={buttonColor ? buttonColor : 'rgb(117, 197, 225)'}
        onClick={onClick}
        style={{ marginRight: 5, fontSize: fontSize}}
      >
        {label}
      </BntEx>
    </div>
  )
};
