import { useState, useEffect } from 'react';
import { SearchInMyLib } from '@components/searchBar';

import { CategorySummary } from '@components/categorySummary';
import { UpdatableDescription } from "@components/description";
import { DragAndDropList } from "@components/reading/listReading";
import { RedGenericButton } from '@components/buttons';
import { EmptyLibrary } from '@components/text';

import { patchLibrary } from '@utils/libraryUtils';
import { saveLibraryChanges } from '@utils/libraryUtils';

import { Library } from '@types_app/index'
import useWindowDimensions from '@hooks/windowDimensions'

import * as Style from './ownedLibrary.style'

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.library

export const OwnedLibrary = ({
  library,
  setLibrary,
}: Props) => {

  const [toUpdate, setToUpdate] = useState<boolean>(false)
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    console.log('liBB', library)
  }, [library])

  const saveChanges = () => {
    saveLibraryChanges(library)
    setToUpdate(false)
  };

  const updateDescription = (newValue) => {
    patchLibrary({ 'description': newValue })
    setLibrary({ ...library, 'description': newValue })
  };

  return (
    <Style.Main>
      {width > 1000 ?
        <Style.Proposition>
          <CategorySummary library={library} />
        </Style.Proposition>
        : <></>
      }

      <div>
        <Style.Head>
          <UpdatableDescription value={library?.description} editInputHandler={updateDescription} />
          <SearchInMyLib library={library}/>
        </Style.Head>
        <Style.Library>
          {library?.categorylibrary_m2m_set.length === 0 ?
            <EmptyLibrary />
            :
            <>
              {toUpdate ?
                <div style={{ margin: 10, display: 'inline-block' }}>
                  <RedGenericButton label={STATIC_VAR.SAVE} onClick={() => saveChanges()} />
                </div> : <div></div>
              }
              <DragAndDropList
                library={library}
                setLibrary={setLibrary}
                toUpdate={toUpdate}
                setToUpdate={setToUpdate}
                saveChanges={saveChanges}
              />
            </>
          }
        </Style.Library>
      </div>
    </Style.Main>
  )
}

type Props = {
  library: Library,
  setLibrary: any,
}