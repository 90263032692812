import styled from "styled-components";

export const SearchInput = styled.input`
  display: block;
  padding: 15px;
  font-size: 17px;
  border-radius: 30px;
  border-color: rgb(202,109,92);
  margin: auto;
`;
export const ResultSearch = styled.div`
  margin:3px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
`;
export const Element = styled.div`
  color: rgb(117, 197, 225);
  background-color: white;
  border-bottom: dashed 1px;
  padding: 5px;
  justify-content: space-between;
  display: flex;
`;
