import axiosInstance from '@services/api'

const baseUrl = '/notification/v1/';

class NotificationService{

    listService  = () => {
        const url = baseUrl + `notification/`
        return axiosInstance.get(url).then((res) => {
            return res
        }).catch(err => { console.log('Error: ', err) });
    };

    deleteService  = (idNotif) => {
        const url = baseUrl + `notification-delete/${idNotif}/`
        return axiosInstance.delete(url).then((res) => {
            return res
        }).catch(err => { console.log('Error: ', err) });
    };    
}

export default new NotificationService();
