import styled from 'styled-components'

export const Main= styled.div`
  display: flex;
  align-items: center;
  // justify-content: right;
  border: solid 2px;
  border-radius: 5px;
  margin: 10px 5px 15px 5px;
  border-color:  rgba(202, 109, 92, 0.3);
  padding: 8px;
  background-color: #f2f2f2;
  `;

export const BoxElement = styled.div`
display: flex;
margin-left:13px;
align-items: center;
justify-content: center;
  font-weight: 350;
  font-size: 30px;
`;
  export const TextDescription = styled.div`
  padding-left:15px;
  padding-right:10px;
  font-size: 20px;
  font-weight: 320;
  font-style: italic;
  margin: 10px 5px 0px 5px;
`;

export const FollowBox = styled.div`
  margin: 5px;
`;
