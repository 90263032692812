import { useState, useEffect, useRef } from 'react';

import { StandardLink } from '@components/links'
import { FollowButton, UnfollowButton } from '@components/buttons'

import UsersService from "@services/usersService";

import { setUserToFollow } from '@utils/followUtils';

import * as Style from "./searchBarUser.style";

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.searchBar

export const SearchBarUser = () => {

  const [inputValue, setInputValue] = useState('');
  const [suggestionsList, setSuggestionsList] = useState([]);
  const refOne = useRef(null)

  useEffect(() => {
    if (inputValue.length > 0) document.addEventListener('click', handleClickOutside, true);
    // 👇️ remove the event listener when component unmounts
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [inputValue]);

  useEffect(() => {
    if (inputValue.length <= 0) return
    const delayDebounceFn = setTimeout(() => {
      UsersService.getFilteredUsers(inputValue).then((data: any) => {
        setSuggestionsList(data.data.results)
      })
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [inputValue]);

  const handleClickOutside = (e) => {
    if (!refOne.current.contains(e.target)) {
      closeDropdownList()
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  const closeDropdownList = () => {
    setInputValue('')
    setSuggestionsList([])
  };

  const updateUserListToFollow = (index: number, action: boolean) => {
    setUserToFollow(suggestionsList[index]['id'], action)
    const result = Array.from(suggestionsList);
    result[index]['followed'] = action
    setSuggestionsList(result)
  }

  return (
    <div className="suggestions-list" ref={refOne} style={{ borderColor: 'rgba(202,109,92,1)' }}>
      <Style.SearchInput
        className="user-input"
        type="text"
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
        placeholder={STATIC_VAR.SEARCH_USER}
      />

      <Style.ResultSearch>
        {suggestionsList.map((user, index) => (
          <Style.Element key={index}>
            <StandardLink
              label={user['username']}
              path={'/library/' + user['id']}
              fontSize={22}
            />
            <div>
              {user.followed === false ?
                <FollowButton onClick={() => updateUserListToFollow(index, true)} />
                :
                <UnfollowButton onClick={() => updateUserListToFollow(index, false)} />
              }
            </div>
          </Style.Element>
        ))}
      </Style.ResultSearch>
    </div>
  )
};


