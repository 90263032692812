import * as Style from './welcomeText.style';

export const WelcomeText = () => {

    return (
      <div>
        <Style.TitleText>
          <div> les mémos de votre Bibliothèque </div>
        </Style.TitleText>
        <Style.Box>
          <Style.ColorBox>
            <Style.Question> Classifiez </Style.Question>
            <Style.Response> Définissez vos catégories pour y ranger vos livres. </Style.Response>
          </Style.ColorBox>
  
          <Style.ColorBox>
            <Style.Question> Annotez </Style.Question>
            <Style.Response>  Écrivez vos avis et
              recopiez les passages inspirant de vos livres. </Style.Response>
          </Style.ColorBox>
  
          <Style.ColorBox>
            <Style.Question> Échangez </Style.Question>
            <Style.Response> Allez jeter un coup d'oeil dans les bibliothèques voisines.
            </Style.Response>
          </Style.ColorBox>
        </Style.Box>
      </div>
    )
  }
  
  