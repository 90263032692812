import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { SameReadingUserList } from '@components/user';
import { CompleteReadingDescription } from '@components/reading/singleReading';

import { getOrCreateReading } from '@utils/libraryUtils';

import { ReadingBookGoogleAPI } from '@types_app/index';

import * as Style from './reading.style';

export const Reading = () => {

  const [reading, setReading] = useState<ReadingBookGoogleAPI>()
  const params = useParams();

  useEffect(() => {
    getOrCreateReading(params, setReading)
  }, [params])
  useEffect(() => {
    console.log('reading', reading)
  }, [reading])

  return (
    <Style.GridMain>
      {reading ?
        <>
          <CompleteReadingDescription reading={reading} />
          <SameReadingUserList
            readingId={reading.id}
            userId={Number(localStorage.getItem('user_id'))} />
        </>
        : <></>
      }
    </Style.GridMain>
  )
}
