import styled from 'styled-components'

export const Box = styled.div`
  margin: 5px;
  margin-bottom: 15px;
`;
export const InputText = styled.input`
  border: solid 1px;
  border-color: rgb(246,211,110);
  height: 42px;
  width: 100%;
  font-size:20px;
  padding-left: 15px;
  border-radius: 30px;
`;
export const LabelForm = styled.div`
  margin: 5px;
  color: rgb(202, 109, 92);
  font-size:21px;
  font-weight: 490;
  // width: 40%;
`;

