import styled from "styled-components";

export const FlexGridCss = styled.div`
  display: flex;
`;
export const MainInfo = styled.div`
  min-width: 30%;
  display: flex;
`;
export const Category = styled.div`
  background-color: rgba(24,183,108,0.2);
  text-align: center;
  font-size: 18px;
  padding: 8px;
  border-radius: 5px;
`;
export const ImgCss = styled.img`
  height:150px;
  margin-right:15px;
  box-shadow: 4px 3px 4px #837e7d;
`;
export const TitleCss = styled.div`
  font-size: 20px;
  font-weight: 510;
  display: inline;
`;
export const SudtitleCss = styled.div`
  font-size: 15px;
  font-weight: 430;
  display: inline;
  font-style: italic;
`;
export const AuthorCss = styled.div`
  font-size: 17px;
  font-weight: 500;
`;
export const DateCss = styled.div`
  font-style: italic;
  display: inline;
`;
export const Description = styled.div`
  text-align: justify;
  padding:20px;
  margin-left:10px;
  background-color: rgba(236,236,236,0.8);
`;