import styled from "styled-components";

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.connect

export const Text = styled.div`
  font-size:21px;
  font-weight: 550;
  line-height: 50px;
  text-align: justify;
`;

export const AccountCreated = () => {

  return (
    <Text>
      {STATIC_VAR.ACCOUNT_CREATED}
      {STATIC_VAR.ACCOUNT_CREATED_TEXT}
    </Text>
  )
}

