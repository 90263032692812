import { useState, useEffect } from "react";

import { TextFieldBasic } from '@components/forms';
import { LittleDescription } from '@components/reading/singleReading';
import { GenericButton, AddButtonModal } from '@components/buttons';

import { addCategory } from '@utils/categoryUtils';
import { addNewReading } from '@utils/libraryUtils';

import { BookGoogleAPI, Library, categorylibrary_m2m_set } from '@types_app/index';

import * as Style from './addToLibrary.style'

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.modal.addToLibrary

export const AddToLibraryModal = ({
  reading,
  handleClose,
  library,
  setLibrary,
}: Props) => {

  const [addCategorie, setAddCategorie] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState<string>("");
  const [categorySelect, setCategorySelect] = useState<categorylibrary_m2m_set>();

  useEffect(() => {
    if (library.categorylibrary_m2m_set.length > 0) {
      setCategorySelect(library.categorylibrary_m2m_set[0])
    }
  }, [library])

  const registerAndClose = () => {
    const res = addNewReading(categorySelect, reading, 'gab', setLibrary);
    if (!res) handleClose();
  }

  const addCategories = () => {
    const newCategory = addCategory(newCategoryName, library, setLibrary)
    setAddCategorie(!addCategorie)
    setNewCategoryName('')
    setCategorySelect(newCategory)
  }

  return (
    <Style.Modal show>
      <Style.ModalMain>
        <Style.HeaderCss> <LittleDescription reading={reading} /> </Style.HeaderCss>
        <div>
          <Style.SelectCss name="choice" onChange={(e) => setCategorySelect(library.categorylibrary_m2m_set[e.target.value])}>
            {library.categorylibrary_m2m_set.map((value, index) => (
              <option key={index} value={index} >{value.name}</option>
            ))}
          </Style.SelectCss>
        </div>
        {
          addCategorie ?
            <div style={{ display: 'inline-block' }}>
              <TextFieldBasic
                id={STATIC_VAR.CATEGORY}
                label={STATIC_VAR.CATEGORY}
                value={newCategoryName}
                onChange={e => {
                  e.stopPropagation()
                  setNewCategoryName(e.target.value)
                }}
              />
              <Style.CategoryCss>
                <GenericButton label={STATIC_VAR.CANCEL} onClick={() => setAddCategorie(!addCategorie)} buttonColor="rgba(202, 109, 92, 0.5)" />
                <GenericButton label={STATIC_VAR.ADD} onClick={() => addCategories()} buttonColor="rgba(117, 197, 225, 0.5)" />
              </Style.CategoryCss>
            </div>
            :
            <div>
              <AddButtonModal onClick={() => setAddCategorie(!addCategorie)} />
              <Style.ButtonsCss>
                <GenericButton label={STATIC_VAR.CANCEL} onClick={() => handleClose()} buttonColor="rgb(202, 109, 92)" />
                <GenericButton label={STATIC_VAR.REGISTER} onClick={() => registerAndClose()} buttonColor="rgb(117, 197, 225)" />
              </Style.ButtonsCss>
            </div>
        }



      </Style.ModalMain>
    </Style.Modal>
  );
};

type Props = {
  reading: BookGoogleAPI;
  handleClose: () => void;
  library: Library,
  setLibrary: any,
}