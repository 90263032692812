import { Capitalize } from '@utils';

import * as Style from './textField.style';
  
export const TextFieldBasic = (props) => {
    return (
      <Style.Box>
        <Style.LabelForm >
        {Capitalize(props.label)}&#160;
        </Style.LabelForm>
          <Style.InputText
            type="text"
            name={props.label}
            id={props.id}
            value={props.value ? props.value : ''}
            onChange={props.onChange} />
      </Style.Box>
    )
  }

export const HiddenTextField = (props) => {
  return (
    <Style.Box>
      <Style.LabelForm>
      {Capitalize(props.label)}&#160;
      </Style.LabelForm>
        <Style.InputText
          type="password"
          name={props.label}
          id={props.id}
          value={props.value ? props.value : ''}
          onChange={props.onChange} />
    </Style.Box>
  )
}

  