import { Capitalize } from '@utils';
import styled from 'styled-components'


type StyledTheTextProps = {
  fontSize?: string;
}
type Props = {
  value: string;
  fontSize?: string;
  quote?: boolean;
}

export const TheText = styled.div<StyledTheTextProps>`
  padding-left:5px;
  padding-right:5px;
  font-size:${(props) => props.fontSize  ? props.fontSize : '20px'};
  font-weight: 520;
`;

export const Description = ({
  value,
  fontSize,
  quote
}: Props) => {

  return (
    <>
    {quote ?
      <TheText fontSize={fontSize}> "{Capitalize(value)}" </TheText>
      :
      <TheText fontSize={fontSize}> {Capitalize(value)} </TheText>
    }
    </>
  )
}
