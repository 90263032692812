import { BsPersonCircle } from "react-icons/bs";
import { useState, useEffect } from 'react';
// import { ProfilPicture } from '@components/user';
import { setUserToFollow } from '@utils/followUtils';
import { FollowButton, UnfollowButton } from '@components/buttons';

import { User } from '@types_app/index';

import * as Style from './littleDescriptionUser.style';

export const LittleDescriptionUser = ({
  user,
  description,
}: Props) => {
  const [userLib, setUserLib] = useState(user)

  useEffect(() => {
    setUserLib(user)
  }, [user]);

  const updateUserToFollow = (user_id: number, action: boolean) => {
    setUserToFollow(user_id, action)
    setUserLib({ ...userLib, 'followed': action })
  }

  return (
    <Style.Main>
        <div style={{ display: 'flex' }}>
          <Style.BoxElement> 
            <BsPersonCircle size="25px" /> 
            <Style.BoxElement> {userLib?.username} </Style.BoxElement>
          </Style.BoxElement>
          <Style.TextDescription > {description} </Style.TextDescription>
        </div>

        <Style.FollowBox>

          {userLib?.followed === false ?
            <FollowButton onClick={() => updateUserToFollow(user.id, true)} />
            :
            <UnfollowButton onClick={() => updateUserToFollow(user.id, false)} />
          }
        </Style.FollowBox>
    </Style.Main>
  )
};

type Props = {
  user: User,
  description: string,
}
