import styled from "styled-components";

export const Main = styled.div`
  display: flex;
`;
export const Library = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const Head = styled.div`
  display: flex;
  margin-bottom:20px;
  justify-content: space-between;
`;
export const Proposition = styled.div`
  width:400px;
  position: relative;
`;


