import { StandardLink } from '@components/links';
import { splitDate } from '@utils';
import { BookGoogleAPI } from '@types_app/index';

import * as Style from './littleDescription.style';

export const LittleDescription = ({
  reading,
}: Props) => {

  const getImgUrl = () => {
    if ('imageLinks' in reading.volumeInfo) {
      if ('thumbnail' in reading.volumeInfo.imageLinks) {
        return reading.volumeInfo.imageLinks.thumbnail
      } else if ('smallThumbnail'  in reading.volumeInfo.imageLinks) {
        return reading.volumeInfo.imageLinks.smallThumbnail
      }
    } else
      return null
  }
  return (
      <Style.ImgTxt>
        {getImgUrl()? <Style.ImgCss src={getImgUrl()} />: <Style.NoImg/>}

        <div>
          <Style.TitleCss>
            <StandardLink
              path={ '/reading/show/' + 'gab'  + '/' + reading.id }
              params={{ reading: reading, }}
              label={reading.volumeInfo.title}
            />    
          <Style.SudtitleCss>
            {reading.volumeInfo.subtitle ? ', ' + reading.volumeInfo.subtitle  : ''}
          </Style.SudtitleCss>
          </Style.TitleCss>

          <div style={{display:'flex'}}>
          <Style.AuthorCss>
            {reading.volumeInfo.authors ? reading.volumeInfo.authors.join(' - ') : ''} &#160;
          <Style.DateCss>
            ({splitDate(reading.volumeInfo.publishedDate)})
          </Style.DateCss>
          </Style.AuthorCss>
          </div>

        </div>
      </Style.ImgTxt>
  );
};

type Props = {
  reading: BookGoogleAPI;
}