import { EditTextInput } from '@components/forms';

import * as Style from './updatableDescription.style';

type Props = {
  value: string;
  editInputHandler: any;
  fontSize?: string;
}

export const UpdatableDescription = ({
  value,
  editInputHandler,
  fontSize,
}: Props) => {

  return (
    <Style.Description>
      <EditTextInput
        value={value}
        editInputHandler={editInputHandler}
        fontSize={fontSize ? fontSize : '19px'} />
    </Style.Description>
  )
}
