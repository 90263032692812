import { useState } from 'react';

import { InputButton } from '@components/forms'
import * as Style from './inputImage.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.form

export const InputImage = ({
  applyChanges
}) => {

  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [registerButton, setregisterButton] = useState(false);
  const [error, setError] = useState(null);

  const validFile = (file) => {
    const filesize = ((file.size / 1024) / 1024); // MB
    if (filesize <= 1) {
      setError(null)
      return true
    }
    return false
  };
  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!validFile(event.target.files[0])) {
      setError(STATIC_VAR.ERROR_IMAGE)
    } else {
      // var new_file = new File([event.target.files[0]], localStorage.getItem('user_name') + 'profil_picture');
      setImage(event.target.files[0]);
      setImageUrl(URL.createObjectURL(event.target.files[0]));
      setregisterButton(true)
    }
  };
  const registerImage = () => {
    applyChanges(image, 'profil_picture')
    setregisterButton(false)
    setImageUrl('')
    setImage(null)
  };

  return (
    <div>
      <Style.InputFile
        type="file"
        accept="image/jpeg,image/png"
        id="fileInput"
        onChange={onImageChange}
      />
      <div>
        <Style.LabelInputFile htmlFor="fileInput">
          {STATIC_VAR.UPDATE_IMAGE}
        </Style.LabelInputFile>
        <Style.ImageUpload src={imageUrl} />

        {registerButton ?
          <InputButton label={STATIC_VAR.REGISTER} onClick={() => registerImage()} />
          : <></>
        }

        <Style.ErrorMessage> {error ? error : null} </Style.ErrorMessage>
      </div>
    </div>
  )
}
