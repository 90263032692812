import styled from "styled-components";

const url = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Painting_by_Julia_Colavita_20101016.7D.06826_SML_%285103242019%29.jpg/640px-Painting_by_Julia_Colavita_20101016.7D.06826_SML_%285103242019%29.jpg"
export const Nav = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width:100%;
  height: 70px;
  background: linear-gradient(to left, rgba(255,255,255,0.2) 78%,
  rgba(255,255,255,0.9)), url(${url});
`;
