import { LittleDescription } from '@components/reading/singleReading';

import * as Style from "./dropdownListLib.style";

export const DropdownListLib = ({
  suggestions,
}: Props) => {

  return (
    <Style.BookList>
      {suggestions.map((suggestion, index) => (
        <Style.BookPropositionElement key={index}>
          <LittleDescription reading={suggestion?.reading.data} />
          <Style.LinkNotebook href={'/myNotebook/' + suggestion?.notebook.id}/>
        </Style.BookPropositionElement>
      ))}
    </Style.BookList>
  );
};

type Props = {
  suggestions: any[];
}
