import { useState, useEffect } from 'react';
import { BsPersonSquare } from "react-icons/bs";

import { Description } from "@components/description";
import { SimpleLink, StandardLink } from '@components/links';

import { getSameReadingUser } from '@utils/libraryUtils';

import { UserList } from '@types_app/index';

import * as Style from './sameReadingUserList.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.user.listUser.sameReadingUserList

export const SameReadingUserList = ({
  readingId,
  userId
}: Props) => {

  const [userList, setUserList] = useState<UserList>();
  const [myNotebookid, setmyNotebookId] = useState<number>();

  useEffect(() => {
    if (!userList) return
    userList.results.forEach((value, i) => {
      if (value.CategoryLibrary_m2m.library.user.id.toString() === localStorage.getItem('user_id')) {
        setmyNotebookId(value.notebook)
      }
    })
  }, [userList]);

  useEffect(() => {
    if (readingId) {
      getSameReadingUser(readingId, setUserList)
    }
  }, [readingId]);

  return (
    <Style.Main>
      <Style.Title>
        <div> {STATIC_VAR.SAME_READING} </div>
        <SimpleLink path={'/myNotebook/' + myNotebookid} label={STATIC_VAR.NOTE_ICONE} />
      </Style.Title>
      {userList?.results?.map((user, i) => (
        <Style.UserElement key={i}>
          <Style.Box>
            <Style.Box>
              <Description value={user.CategoryLibrary_m2m.library.user.username}
                fontSize='19px'
              />
            </Style.Box>

            <Style.Box>
              <Description value={user.CategoryLibrary_m2m.library.description}
                fontSize='15px'
                quote={true}
              />
            </Style.Box>
          </Style.Box>

          <Style.Box >
            <SimpleLink path={'/notebook/' + user?.notebook} label={STATIC_VAR.NOTEBOOK} />
            <SimpleLink path={'/library/' + user?.CategoryLibrary_m2m.library.user.id} label={STATIC_VAR.LIBRARY} />
          </Style.Box>
        </Style.UserElement>
      ))}
    </Style.Main>
  )
};

type Props = {
  readingId: number;
  userId: number;
}