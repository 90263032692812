import { useState, useEffect } from 'react';

import { SearchBar, SearchBarUser } from '@components/searchBar';
import { SmallSizeNotif } from '@components/notification';
import { MenuHeadBar } from '@components/menu';

import { getLibrary } from '@utils/libraryUtils';

import { Library } from '@types_app/index';
import useWindowDimensions from '@hooks/windowDimensions'

import * as Style from './main.style';

export const Main = ({ children }) => {

  const { height, width } = useWindowDimensions();
  const [library, setLibrary] = useState<Library>();

  useEffect(() => {
    getLibrary(localStorage.getItem('user_id'), setLibrary)
  }, []);
  // useEffect(() => {
  //   console.log('children', children)
  // }, [children]);

  return (
    <>
      <SearchBar library={library} setLibrary={setLibrary} />
      <MenuHeadBar />

      <Style.GridMain>
        <Style.Page>
          {children(library, setLibrary)}
        </Style.Page>
        

        <Style.Proposition>
          {width > 500 ?
            <>
              <SearchBarUser />
              <SmallSizeNotif />
            </>
            : <></>
          }
        </Style.Proposition>
      </Style.GridMain>
    </>
  )
}


// SIgnaler des fautes d'orthographe

// C'est un système unique en France : les abonné·es peuvent signaler des
// fautes et coquilles qui resteraient. Il suffit pour cela de surligner le
//  texte concerné (si possible en incluant les mots qui précèdent et suivent),
//   puis de cliquer sur le picto "A coché" et d'indiquer votre correction,
//   immédiatement transmise à la rédaction.