import styled from 'styled-components';

export interface ModelProps {
  show?: boolean;
}
export const Modal = styled.div`
	z-index: 60;
	display: ${(props: ModelProps) => (props.show ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width:100vw;
	background: rgba(0,0,0,0.5);
`;
export const ModalMain = styled.div`
  position:fixed;
  background: white;
  width: 50%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 10px;
`;
export const HeaderCss = styled.div`
  background-color: rgba(246,211,110,0.5);
  padding: 8px;
`;
export const CategoryCss = styled.div`
  display: flex;
  justify-content: end;

`;
export const SelectCss = styled.select`
  background-color: rgba(246, 211, 110, 0.5);
  border: none;
  margin: 8px 0px;
  padding: 8px 20px;
  border-radius: 2px;
  font-size: 18px;
`;
export const ButtonsCss = styled.div`
  display: flex;
  justify-content: end;
`;
