import { LittleDescription } from '@components/reading/singleReading';
import { GenericButton } from '@components/buttons';

import { ReadingLibrary_m2m } from '@types_app/index';

import * as Style from './genericModal.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.modal.genericModal

export const GenericModal = ({
  text,
  writing,
  setReadingDelete,
  callBack
}: Props) => {

  const closeModal = (response: Boolean) => {
    if (response) callBack()
    setReadingDelete()
  }

  return (
        <Style.Modal >
          <Style.ModalMain>

            <Style.HeaderCss> {text} </Style.HeaderCss>
            <LittleDescription reading={writing.reading.data} />


            <Style.ButtonsCss>
                <GenericButton label={STATIC_VAR.CANCEL} onClick={() => closeModal(false)} buttonColor="rgb(202, 109, 92)" />
                <GenericButton label={STATIC_VAR.DELETE} onClick={() => closeModal(true)} buttonColor="rgb(117, 197, 225)" />
              </Style.ButtonsCss>
          </Style.ModalMain>
        </Style.Modal>
  );
};

type Props = {
  text: string;
  writing: ReadingLibrary_m2m;
  setReadingDelete: any;
  callBack: any;
}