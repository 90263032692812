import styled from "styled-components";

export const GenericPointerButton = styled.div`
  padding: 3px;
  cursor: pointer;
  &:hover {
    transform: scale(1.2)
  }
`;

export const sizeIcon = '20px';