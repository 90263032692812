import React from 'react';
import './app.css'
import { GridLayout, Main } from './app.style';
import { HeadBar } from '@layouts/navigation';
import AllRoutes from '@constants/routes';

class App extends React.Component {
  render() {
    return (
      <GridLayout>

        <HeadBar />

        <Main >
          <AllRoutes />
        </Main >
      </GridLayout>
    )
  }
}
export default App;
