import { MouseEventHandler } from 'react';
import { IoMdAddCircleOutline } from "react-icons/io";

type Props = {
  onClick: MouseEventHandler,
}

export const AddButtonModal = ({
  onClick,
}: Props) => {
  return (
    <div style={{padding: 12 }}>
      <IoMdAddCircleOutline  size="25px" onMouseUp={e => e.stopPropagation()} onClick={(e) => {
        e.stopPropagation()
        onClick(e)
      }
      }/>
    </div>
  )
}

