import { useState, useEffect } from 'react';

import { MenuHeadBar } from '@components/menu';
import { Updatable } from '@components/updatable';
import { InputImage, InputListChoices, EditNumberInput } from '@components/forms'

import { getSettings, updateSettings } from '@utils/usersUtils'
import { SettingsUser } from '@types_app/index';

import * as Style from './mySettings.style';

export const MySettings = () => {

  const [settings, setSettings] = useState<SettingsUser>({} as SettingsUser)
  const [changeApplied, setChangeApplied] = useState(false)
  // const [postalCode, setPostalCode] = useState<number>(null)

  useEffect(() => {
    getSettings(localStorage.getItem('user_id'), setSettings)
  }, [])


  const applyChanges = (value, typeValue) => {
    let data = new FormData();
    if (typeValue === 'profil_picture')
    data.append(typeValue, value, 'profil_picture_' + localStorage.getItem('user_id') + '.png')
    else
      data.append(typeValue, value)
    
    updateSettings(localStorage.getItem('user_id'), data, setSettings)
    setChangeApplied(true)
  };

  return (
    <Style.Main>
      <MenuHeadBar />

      <Style.Titleh2>Settings</Style.Titleh2>

      <Style.UpdatableCss>
        <Style.Title>Code postal:</Style.Title>
        <Updatable
          resultToDisplay={() => <div>{settings?.postal_code ? settings?.postal_code : 'no postal code'}</div>}
          inputToUpdate={() => <EditNumberInput value={settings?.postal_code} applyChanges={applyChanges}/>}
          changeApplied={changeApplied}
        />
      </Style.UpdatableCss>

      <Style.UpdatableCss>
        <Style.Title>Photo de profil:</Style.Title>
        <Updatable
          resultToDisplay={() => <Style.ProfilPictureImg src={settings['profil_picture']} />}
          inputToUpdate={() => <InputImage applyChanges={applyChanges} />}
          changeApplied={changeApplied}
        />
      </Style.UpdatableCss>

      <Style.UpdatableCss>
        <Style.Title>Language:</Style.Title>
        <Updatable
          resultToDisplay={() => <div>{settings?.language}</div>}
          inputToUpdate={() => <InputListChoices options={settings?.language_options} applyChanges={applyChanges} />}
          changeApplied={changeApplied}
        />
      </Style.UpdatableCss>

    </Style.Main>
  )
}

