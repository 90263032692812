import styled from 'styled-components';

export const Submit = styled.input`
  margin: 6px;
  padding: 1px 20px 5px 20px;
  cursor: pointer;
  font-size:21px;
  font-weight: 490;
  border-radius: 20px;
  color: white;
  background-color: rgb(117, 197, 225);
  :hover {
      color: rgb(226, 150, 95);
      font-weight: bold;
  }
`;

export const InputButton = ({
    label,
    onClick
}) => {
    return (
        <Submit type="button" value={label} onClick={onClick} />
    )
};
