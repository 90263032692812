import styled from 'styled-components';

import { Capitalize } from '@utils';

export const DisplayedText = styled.div`
  padding-left:5px;
  min-height: 30px;
  padding-right:5px;
  margin: 7px 0px 10px 5px;
  font-size:25px;
  font-weight:400;
`;

type Props = {
  text: String,
}

export const BoldText = ({
  text,
}: Props) => {
  return (
    <DisplayedText> {Capitalize(text)}&#160; </DisplayedText>
  )
};
