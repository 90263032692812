import { Link } from "react-router-dom";

import * as Style from './headBar.style'
import logo from '@styles/img/LOGOTYPE_LESEN.png';

export const HeadBar = () => {

  return (
    <Style.Nav>
      <Link to='/'>
        <img height='50' src={logo} alt="Logo" />
      </Link>
    </Style.Nav>
  )
}
