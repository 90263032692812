import { MenuHeadBar } from '@components/menu';
import { WelcomeText } from '@components/text';

import * as Style from './welcome.style';

export const Welcome = () => {

  return (
    <Style.Main>
      <MenuHeadBar/>
      <WelcomeText/>
      <Style.ImageBox>
      <Style.Image src={require('@assets/ladybook.png')} />
      </Style.ImageBox>
      {/* <img height='40' src={require('@assets/LOGOTYPE_LESEN_original_Plan.png')} ></img> */}

    </Style.Main>
  )
}

