import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { LittleDescriptionUser } from '@components/user';
import { CategorySummary } from '@components/categorySummary';
import { ReadingElement } from '@components/reading/singleReading';
import { DisplayChevron } from '@components/buttons';

import useWindowDimensions from '@hooks/windowDimensions'

import { Capitalize } from '@utils';
import { getLibrary } from '@utils/libraryUtils';

import { Library } from '@types_app/index';

import * as Style from './library.style';

export const DisplayLibrary = () => {

  const [maxHeight, setMaxHeight] = useState('315px');
  const [library, setLibrary] = useState<Library>()
  const { id } = useParams();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    getLibrary(id, setLibrary)
  }, [id])


  return (
    <Style.Main>
      {width > 1000 ?
        <Style.Proposition>
          <CategorySummary library={library} />
        </Style.Proposition>
        : <></>
      }
      <Style.Library>
        <LittleDescriptionUser user={library?.user} description={library?.description} />

        <Style.AllCategories>
          {library?.categorylibrary_m2m_set.map((category, i) => (
            <Style.Category key={i}>
              <div style={{ height: 'auto', maxHeight: maxHeight, minHeight: '100px', overflow: 'auto', marginBottom: 15 }}>

                <Style.HeaderReadingList> {Capitalize(category.name)} </Style.HeaderReadingList>
                {category?.readinglibrary_m2m_set.map((value, index) => (
                  <ReadingElement key={index} reading={value} />
                ))}
              </div>
              <Style.DivChevron>
                {DisplayChevron(category.readinglibrary_m2m_set.length, maxHeight, setMaxHeight)}
              </Style.DivChevron>
            </Style.Category>
          ))}
        </Style.AllCategories>

      </Style.Library>
    </Style.Main>
  )
}
