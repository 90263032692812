import styled from 'styled-components';

const InputField = styled.input`
  font-family: inherit;
  border: 0;
  margin-bottom: 10px;
  margin-left: 10px;
  outline: 0;
  font-size: 1.3rem;
  color: bleu;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  &::placeholder {
    color: transparent;
  }
  &:value {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
`;

interface Props {
  label: string;
  onLabelChange: (onLabelChange: string) => void;
}

export const TextFieldTitle: React.FC<Props> = ({
  label,
  onLabelChange
}) => {
  return (
    <InputField value={label} onChange={e => onLabelChange(e.target.value)} />
  )
};

