import { useState, useEffect } from 'react';

import { ChevronDoubleUp, ChevronDoubleDown } from '@components/buttons';
import { splitDate } from '@utils';

import { ReadingBookGoogleAPI, BookGoogleAPI } from '@types_app/index';

import * as Style from './readingDescription.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.reading.singleReading

export const ReadingDescription = ({
  reading
}: Props) => {
  const [maxHeightDescription, setMaxHeightDescription] = useState<boolean>(true)
  const [read, setRead] = useState<BookGoogleAPI>({} as BookGoogleAPI)

  useEffect(() => {
    if (reading) setRead(reading.data)
  }, [reading])

  const getImgUrl = (readInfo) => {
    if ('volumeInfo' in readInfo) {
      if ('imageLinks' in readInfo.volumeInfo) {
        if ('thumbnail' in readInfo.volumeInfo.imageLinks) {
          return readInfo.volumeInfo.imageLinks.thumbnail
        } else if ('smallThumbnail' in readInfo.volumeInfo.imageLinks) {
          return readInfo.volumeInfo.imageLinks.smallThumbnail
        }
      } else
        return null
    }
  };
  const DisplayChevron = () => {
    if (maxHeightDescription === true)
      return (<ChevronDoubleDown onClick={() => setMaxHeightDescription(!maxHeightDescription)} />);
    else
      return (<ChevronDoubleUp onClick={() => setMaxHeightDescription(!maxHeightDescription)} />);
  };

  return (
    <div>
      <Style.Book>
        {getImgUrl(read)? <Style.ImgCss src={getImgUrl(read)} />: <Style.NoImg></Style.NoImg>}
          <Style.BookText>
            <Style.TitleCss> {read?.volumeInfo?.title} </Style.TitleCss>
            <Style.SudtitleCss> {read?.volumeInfo?.subtitle ? read?.volumeInfo?.subtitle : STATIC_VAR.UNKNOW} </Style.SudtitleCss>
            {read?.volumeInfo?.authors ? read?.volumeInfo?.authors.join(' - ') : STATIC_VAR.UNKNOW}
            <div> ({splitDate(read?.volumeInfo?.publishedDate)}) </div>
            <div> {STATIC_VAR.TO}&#160;{read?.volumeInfo?.publisher} </div>
          </Style.BookText>
      </Style.Book>

      <Style.Description maxHeightDescription={maxHeightDescription} >
        {read?.volumeInfo?.description}
      </Style.Description>

      <Style.DivChevron> <DisplayChevron /> </Style.DivChevron>

    </div>
  )
}

type Props = {
  reading: ReadingBookGoogleAPI;
}
