import { useState, useEffect } from 'react';
import { InputButton } from '@components/forms'

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.form

export const InputListChoices = ({
  options,
  applyChanges
}: Props) => {

  const [selectedOption, setselectedOption] = useState({})
  const [registerButton, setregisterButton] = useState(false);

  useEffect(() => {
    if (options.length > 0) {
      setselectedOption(options[0])
    }
  }, [])

  const choiceOnChange = (event) => {
    setselectedOption(event.target.value);
    setregisterButton(true)
  };

  const register = () => {
    applyChanges(selectedOption, 'language')
    setregisterButton(false)
  }

  return (
    <div className="select-container">
      <select value={selectedOption['label']} onChange={choiceOnChange}>
        {options?.map((option, i) => (
          <option key={i} value={option}>{option}</option>
        ))}
      </select>

      {registerButton ?
        <InputButton label={STATIC_VAR.REGISTER} onClick={() => register()} />
        : <></>
      }
    </div>
  )
}
type Props = {
  options: [][];
  applyChanges: any;
}