import React from 'react';
import { Routes, Route } from 'react-router-dom'

import {
  Main,
  OwnedLibrary,
  DisplayNotebook,
  MyNotebook,
  Reading,
  DisplayLibrary,
  Connect,
  ActivateAccount,
  Ticketing,
  NotFound,
  Instruction,
  Welcome,
  MySettings,
  Notification
} from '@pages';

import { NewPassword, AuthOnly } from '@features/authentication'

function AllRoutes() {

  return (
    <Routes>
      <Route path='/connect' element={<Connect />} />
      <Route path='/reset-password' element={<NewPassword />} />
      <Route path='/welcome' element={<Welcome />} />
      <Route path='/notification' element={<Notification />} />
      <Route path='/activate-account' element={<ActivateAccount />} />

      <Route path="/notification"
        element={
          <AuthOnly>
            <Route path='/notification' element={<Notification />} />
          </AuthOnly>
        }
      />

      <Route path="/instruction"
        element={
          <AuthOnly>
            <Main children={(library, setLibrary) =><Instruction />}/>
          </AuthOnly>
        }
      />
      <Route path="/contact"
        element={
          <AuthOnly>
            <Main children={(library, setLibrary) =><Ticketing /> }/>
          </AuthOnly>
        }
      />


      <Route path='/settings' element={<MySettings />} />


      <Route path="/"
        element={
          <AuthOnly>
            <Main children={(library, setLibrary) =>< OwnedLibrary library={library} setLibrary={setLibrary} />}/>
          </AuthOnly>
        }
      />
      <Route path='/reading/show' element={<AuthOnly><Main children={(library, setLibrary) =><Reading />} /></AuthOnly>}>
        <Route path=':source/:idFromSource' element={<AuthOnly><Main children={(library, setLibrary) =><Reading />} /></AuthOnly>} />
      </Route>

      <Route path='/myNotebook' element={ <AuthOnly><MyNotebook/></AuthOnly>}>
        <Route path=':id' element={<AuthOnly><MyNotebook /></AuthOnly>} />
      </Route>

      <Route path='/notebook' >
        <Route path=':id' element={<AuthOnly><DisplayNotebook /></AuthOnly>} />
      </Route>
      
      <Route path='/library' element={<AuthOnly><Main children={(library, setLibrary) =><DisplayLibrary />} /></AuthOnly>}>
        <Route path=':id' element={<AuthOnly><Main children={(library, setLibrary) =><DisplayLibrary />} /></AuthOnly>} />
      </Route>

      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default AllRoutes