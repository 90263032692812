import styled from "styled-components";

export const GridMain = styled.div`
  margin-top:75px;
  position: sticky;
  min-width: 10vw;
  max-width: 10vw;
  top: 50px;
  border-right: dashed 2px;
  border-color: rgb(202, 109, 92);
`;

export const Clicable = styled.div`
  cursor: pointer;
`;