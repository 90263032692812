import styled from "styled-components";

export const Main = styled.div`
  width: 80%;
  padding:10px;
  margin: auto;
  margin-top: 50px;
  border-top: solid 1px;
  border-color: rgba(202, 109, 92, 0.3);

`;
export const Title = styled.div`
  margin-left: 13px;
  margin-right: 5px;
  font-size: large;
  font-weight: 400;
  justify-content: space-between;
  display: flex;
`;
export const UserElement = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: dotted 2px;
  // border-color: rgba(171, 110, 150, 1);
  border-color: rgba(202, 109, 92, 0.3);

  :hover {
    background-color: rgba(240, 240, 240, 0.5);
  }
`;
export const Box = styled.div`
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  `;
