import { useState, useEffect } from 'react';

import { getTicket } from '@utils/ticketingUtils'
// import { getTicket, createTicket } from '@utils/ticketingUtils'

import * as Style from './ticketing.style';
import { Ticket } from '@types_app/index';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.ticketing

export const Ticketing = () => {

  const [ticket, setTicket] = useState<Ticket[]>()

  useEffect(() => {
    getTicket(setTicket)
  }, []);


  return (

    <Style.GridMain>

      <Style.TitleText>{STATIC_VAR.CONTACT} 👋</Style.TitleText>
      
      <Style.TextBody>
        <div> {STATIC_VAR.CONTACT_TEXT_P1} </div><br />
        
        <div> {STATIC_VAR.CONTACT_TEXT_P2} </div><br />
      </Style.TextBody>
      <Style.TextBody>
        <div> {STATIC_VAR.CONTACT_TEXT_P4} </div>
        <Style.Mail> 
          {STATIC_VAR.MAIL}<br />
        <Style.LadyBook src={require('@assets/ladybook.png')} />
          </Style.Mail>
      </Style.TextBody>

    </Style.GridMain>
  )
}
