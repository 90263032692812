import { useState, useEffect, useRef } from 'react';
import { AddToLibraryModal } from '@components/modals';

import BooksApiService from "@services/booksApiService";
import { DropdownList } from '@components/reading/listReading';

import { Library } from '@types_app/index';

import * as Style from "./searchBar.style";
import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.searchBar

type Props = {
  library: Library,
  setLibrary: any,
}

export const SearchBar = ({
  library,
  setLibrary
}: Props) => {

  const [inputValue, setInputValue] = useState('');
  const [suggestionsList, setSuggestionsList] = useState([]);
  const refOne = useRef(null)
  const [readingToAdd, setReadingToAdd] = useState();

  useEffect(() => {
    if (inputValue.length > 0) {
      document.addEventListener('click', handleClickOutside, true);
    }
    // 👇️ remove the event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [inputValue]);

  useEffect(() => {
    if (inputValue.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        BooksApiService.getSuggestionList(inputValue).then((data: any) => {
          setSuggestionsList(data.data['data'])
        })
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [inputValue]);

  const handleClickOutside = (e) => {
    if (!refOne.current.contains(e.target)) {
      closeDropdownList()
      document.removeEventListener('click', handleClickOutside, true);
    }
  };
  const closeDropdownList = () => {
    setInputValue('')
    setSuggestionsList([])
    setReadingToAdd(null)
  };

  const ModalToAdd = () => {
    if (readingToAdd) {
      return (
        <AddToLibraryModal
          reading={readingToAdd}
          handleClose={() => closeDropdownList()}
          library={library}
          setLibrary={setLibrary}
        ></AddToLibraryModal>
      )
    } else return (<></>)
  };

  return (
    <div className="suggestions-list" ref={refOne} style={{ borderColor: 'rgba(202,109,92,1)' }}>
      <Style.SearchInput
        className="user-input"
        type="text"
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
        placeholder={STATIC_VAR.SEARCH_BOOK} />
      {suggestionsList.length > 0 ?
        <DropdownList
          suggestions={suggestionsList}
          setReadingToAdd={setReadingToAdd} />
        : <></>
      }
      {ModalToAdd()}
    </div>
  )
};


