import { MouseEventHandler } from 'react';
import { AiFillSave } from "react-icons/ai";

import styled from 'styled-components';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.buttons

export const BntEx = styled.div`
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  padding: 1px 10px 2px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;

  :hover {
    font-size:14px;
  }
`;

type Props = {
  onClick?: MouseEventHandler,
}

export const RegisterButton = ({
  onClick,
}: Props) => {
  return (
    <div>
    </div>
  )
};

export const RegisterButtonActive = ({
  onClick,
}: Props) => {
  return (
    <div>
      <BntEx onClick={onClick} color="rgb(171, 110, 150)" >{STATIC_VAR.REGISTER} </BntEx>
    </div>
  )
};

