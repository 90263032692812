import UsersService from '@services/usersService';
import UserProfileService from '@services/userProfileService';

export const getAllUser = (setUsers: any) => {
    return UsersService.getAllUser().then((
        res: any
      ) => {
        setUsers(res.data.results)
      }).catch((error: any) => console.log('error', error))
};

export const getSettings = (userId: any, setMySettings: any) => {
  return UserProfileService.getSettingsService(userId).then((
      res: any
    ) => {
      setMySettings(res.data)
    }).catch((error: any) => console.log('error', error))
};


export const updateSettings = (userId: any, data: any, setSettings: any) => {
  return UserProfileService.updateSettingsService(userId, data).then((
      res: any
    ) => {
      setSettings(res.data)
    }).catch((error: any) => console.log('error', error))
};