import styled from 'styled-components'

export type StyledOwnerProps = {
  owner?: boolean;
}
export const Description = styled.div`
  background-color: rgba(236,236,236,0.8);
  font-style: italic;
  margin: 8px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 3px 3px 3px #A9A9A9;
`;
