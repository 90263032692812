import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import parse from 'html-react-parser';

import { MenuHeadBar } from '@components/menu';
import { ReadingDescription } from '@components/reading/singleReading';
import { LittleDescriptionUser, SameReadingUserList } from '@components/user';

import { getNotebookData } from '@utils/notebookUtils';

import { ReadingBookGoogleAPI, Notebook, User } from '@types_app/index';

import * as Style from '../notebookGeneric.style';

export const DisplayNotebook = () => {

  const [user, setUser] = useState<User>()
  const [readingId, setReadingId] = useState<number>()
  const [reading, setReading] = useState<ReadingBookGoogleAPI>()
  const [notebook, setNotebook] = useState<Notebook>()

  const { id } = useParams();

  useEffect(() => {
    getNotebookData(id, setReadingId, setReading, setUser, setNotebook)
  }, [id])

  return (
    <Style.Main>
      <MenuHeadBar />
      <LittleDescriptionUser user={user} description={notebook?.description} />

      <Style.MainNotebook>
        <Style.Description>
          <ReadingDescription reading={reading} />
        </Style.Description>

        <Style.BodyNotebook>
          <Style.HeadNotebook > Notes </Style.HeadNotebook>
          {notebook?.data?.blocks?.map((value, index) => (
            <Style.TextDescription key={index} >{parse(value['data']['text'])} </Style.TextDescription>
          ))}
        </Style.BodyNotebook>
      </Style.MainNotebook>

      <SameReadingUserList
        readingId={readingId}
        userId={user?.id}
      />

    </Style.Main>
  )
}
