import { TextError } from './errorMessage.styles';
  
export const ErrorMessage = (props) => {
    return (
      <div>
        <TextError>
            {props.message} 
        </TextError>
      </div>
    )
  }

