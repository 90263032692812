import { Menu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { HiOutlineDotsVertical } from "react-icons/hi";

import { LeftButton, RightButton, DeleteBinButton } from '@components/buttons';

import * as Style from './menuDropdown.style';

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.menu.menuDropdown

const MenuList = ({
  onUp,
  onDown,
  deleteRows,
}: Props) => {

  return (
    <Style.HeadListElement >
      <Style.MenuOption onClick={onUp}> {STATIC_VAR.MOVE}
        <LeftButton onClick={() => console.log('onup')} />
      </Style.MenuOption>
      <Style.MenuOption>  {STATIC_VAR.MOVE}
        <RightButton onClick={onDown} />
      </Style.MenuOption>
      <Style.MenuOption>  {STATIC_VAR.DELETE}
        <DeleteBinButton onClick={deleteRows} />
      </Style.MenuOption>
    </Style.HeadListElement>
  )
}

export const MenuDropdown = ({
  onUp,
  onDown,
  deleteRows,
}: Props) => {

  return (
    <Style.AllMenuButton>
      <Menu menuButton={
        <Style.MenuButton2>
          <HiOutlineDotsVertical size="25px" />
        </Style.MenuButton2>
      } transition
      >
        <MenuList
          onUp={onUp}
          onDown={onDown}
          deleteRows={deleteRows}
        />
      </Menu>
    </Style.AllMenuButton>
  )
}

type Props = {
  onUp: () => void;
  onDown: () => void;
  deleteRows: () => void;

}
