import { useState } from 'react';

import UserProfileService from "@services/userProfileService";
import { TextFieldBasic, InputButton } from '@components/forms'

import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.pages.connect

type INITIAL_STATE = {
  email: string;
}

export const ForgotPassword = () => {

  const [emailForm, setEmailForm] = useState<INITIAL_STATE>({ email: '' });
  const [emailSend, setEmailSend] = useState<boolean>(false);

  const submitForm = (event) => {
    event.preventDefault();
    UserProfileService.ask_password_reset(emailForm).then((
      data) => { setEmailSend(true) })
  }

  const handleChange = (event) => {
    setEmailForm({
      ...emailForm,
      [event.target.id]: event.target.value,
    });
  };

  return (
    <div style={{ width: '100%' }}>
      {emailSend == false ?
        <form >
          <TextFieldBasic id='email' label='email' value={emailForm.email} onChange={handleChange} />

          <InputButton label='envoyer' onClick={submitForm} />
        </form>
        : <>L'EMAIL A ÉTÉ ENVOYÉ</>
      }
    </div>
  )
}