import { useState, useEffect } from 'react';

import { RegisterButtonActive } from '@components/buttons';
import { Capitalize } from '@utils';

import * as Style from './editTextInput.style';
import JSON_FILE from '@context/text/francais/word.json';
const STATIC_VAR = JSON_FILE.components.form

type Props = {
  value: string;
  editInputHandler: any;
  fontSize?: string
}

export const EditTextInput = ({
  value,
  editInputHandler,
  fontSize,
}: Props) => {

  const [edit, setEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(value ? value : "");

  useEffect(() => {
    setEditValue(value ? value : "")
  }, [value]);

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value);
  };

  const registerEdit = () => {
    editInputHandler(editValue)
    setEdit(false)
  }

  return (
    <div>
      {edit === true ?
        <Style.InputText>
          <Style.InputFormText type='text'
            value={editValue}
            onChange={inputHandler} />
          <RegisterButtonActive onClick={() => registerEdit()} />
        </Style.InputText>
        :
        <Style.DisplayText onClick={() => setEdit(true)}>
          <Style.TheText fontSize={fontSize} > {Capitalize(editValue)} </Style.TheText>
        </Style.DisplayText>
      }
    </div>
  )
}
