import styled from "styled-components";
import painting from '@assets/painting_Julia_Colavita_2010.png'

export const Main = styled.div`
  margin-right: 10%;
  margin-left: 10%;
`;
export const ImageBox = styled.div`
  justify-content: space-around;
  display:flex;
  background: linear-gradient(to left, rgba(255,255,255,0.7) 78%,
  rgba(255,255,255,0.7)), url(${painting});
  padding: 20px;
  border-radius: 20px;
`;
export const Connect = styled.div`
  border: solid 1px;
  border-color: rgba(202,109,92,0.8);
  border-radius: 20px;
  padding:25px;
  background-color: rgba(255, 255, 255, 0.8);
`;
export const ToggleWindow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:20px;
  margin-bottom:20px;
`;
export const ImgLogo = styled.img`
  margin-top:10px;
  height:40px;
`;
export const StyledInput = styled.button`
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
  border: 0px;
  font-size: 23px;
  font-weight: 350px;
  outline:none; 
  background-color: rgba(236,236,236,0.8);
`;

export const SimpleLink = styled.div`
  font-size: 15px;
  font-weight: 350px;
  display: flex;
  justify-content: right;
  :hover {
    color: rgb(117, 197, 225);
    cursor: pointer;
  }
`;

export type StyledTabProps = {
  nth: string;
}
export const BoxInput = styled.div<StyledTabProps>`
  ${StyledInput}:nth-child(${(props) => props.nth}) {
    box-shadow: 0px 0px 2px red;
    background-color: rgb(202, 109, 92);
    color: white;  
  }
`;
export const Response = styled.div`
  font-size:22px;
  font-weight: 390;
`;
export const BottomPart = styled.div`
  text-align: center;
`;