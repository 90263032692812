import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  padding: 20px;
  border: solid 10px;
  border-color: rgba(202,109,92,0.8);
  border-radius: 20px;
`;
export const Box = styled.div`
  margin: 20px;
`;

export const SimpleTitle = styled.div`
  font-size: 35px;
  font-weight: 350px;
  margin-bottom: 20px;
`;

export const SimpleLink = styled.div`
  font-size: 20px;
  font-weight: 350px;
  margin-top: 20px;
  :hover {
    color: rgb(117, 197, 225);
    cursor: pointer;
  }
`;