import styled from "styled-components";

export const GridLayout = styled.div`
  display: grid;
  grid-template-areas:
  "nav"
  "main";
  grid-template-rows: 70px;
`;

export const Nav = styled.div`
  grid-area: nav;
`;

export const Main = styled.main`
  grid-area: main;
`;