import styled from "styled-components";

export const Main = styled.div`
  margin-left:10%;
  margin-right:10%;
`;
export const Titleh2 = styled.div`
  font-size: 45px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: rgba(236,236,236,0.8);
  border-radius: 15px;
`;
export const UpdatableCss = styled.div`
  display: flex;
  border-top: solid 1px;
  border-color: rgb(117, 197, 225);
  margin-top:1%;
`;
export const Title = styled.h5`
  padding-top:18px;
`;
export const ProfilPictureImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 5px;
`;
export const Username = styled.p`
  font-size: 43px;
  font-weight: 300;
`;

