import styled from "styled-components";

export const HeaderCss = styled.div`
  color: #7b7b7b;
  background-color: white;
  margin-top:20px;
  font-size: 25px;
  text-align: center;
  padding:5px;
  border: solid 2px;
  border-radius: 5px;
  border-color: rgba(202,109,92,1);
`;
export const ContainerNotifCss = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
export const NotifCss = styled.div`
  color: #7b7b7b;
  max-width: 320px;
  margin-top:5px;
  font-size: 15px;
  font-weight: 515;
  text-align: center;
  padding:2px;
  margin-left: auto;
  display:flex;
  margin-right: auto;
  border-bottom: dashed 2px;
  border-left: dashed 2px;
  border-color: rgba(202,109,92,1);
  em {
    font-weight: 815;
  }
`;
export const TextCss = styled.div`
  padding:2px;
`;
export const ButtonCss = styled.div`
  color: #7b7b7b;
  text-align: center;
`;