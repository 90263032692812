import { useState, useEffect } from 'react';

import * as Style from './updatable.style'
// import { getSettings, updateSettings } from '@utils/usersUtils'

export const Updatable = ({
  resultToDisplay,
  inputToUpdate,
  changeApplied
}) => {

  const [update, setUpdate] = useState(false)

  useEffect(() => {
    setUpdate(false)
  }, [changeApplied])
  
  const handleChange = () => {
    setUpdate(!update)
  };
  
  return (
    <Style.Main>
      {update ?
        <> {inputToUpdate()} </>
        :
        <Style.Box> 
          {resultToDisplay()}
          <Style.UpdateSetting onClick={() => handleChange()}> Modifier </Style.UpdateSetting>
        </Style.Box>
      }
    </Style.Main>
  )
}

