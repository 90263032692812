import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

export const GoBackButton = () => {
  let navigate = useNavigate();

  const goBack = () => {
    // check if all is saved
    navigate(-1)
  }
  return (
    <div style={{margin: '5px', cursor: 'pointer'}}>
      <IoMdArrowRoundBack  size={30} onClick={goBack}/>
    </div>
  )
}

